import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import bg1 from "../../assets/images/bg/prakruthi.jpg";
import bg from "../../assets/images/bg/prakruthi-M.jpg";
import Prakruthi01 from "../../assets/images/hospital/Prakruthi01.png";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  padding: "1rem",
  backgroundColor: "#fff",
};

const cardTitleStyle = {
  backgroundColor: "#f0f0f0",
  borderBottom: "1px solid #ddd",
  textAlign: "center",
  padding: "6px",
  borderRadius: "4px",
};

const cardTextStyle = {
  flexGrow: 1,
  padding: "1rem",
};

const hiddenStyle = {
  position: "absolute",
  left: "-9999px",
  top: "-9999px",
};

export default function Prakruti() {
  const navigate = useNavigate();
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);

  const invoiceDetails = {
    date: "2024-10-09",
    customerName: "John Doe",
    address: "123 Main St, Anytown, USA",
    items: [
      { name: "Widget", quantity: 4, price: 10 },
      { name: "Gadget", quantity: 2, price: 15 },
    ],
  };

  const generatePDF = () => {
    const input = document.getElementById("invoice");
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 0, 0);
        pdf.save("invoice.pdf");
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          marginTop: isMobile ? "2rem" : "",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-1 text-white title-dark">
                  Hospital ~ Prakruti
                </h3>
                <p
                  className="para-desc mx-auto text-white-50"
                  style={{ display: isMobile ? "none" : "block" }}
                >
                  Nature's Cure Service harnesses the inherent healing power of
                  nature to promote optimal well-being in synergy with
                  traditional Ayurvedic practices.
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hospital ~ Prakruti
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              <h4>Prakruti</h4>
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="card">
                <img src={Prakruthi01} className="img-fluid" alt="" />
              </div>
            </div>

            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h5>Nature Cure - Prakriti Shakti</h5>
                </div>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    Nature cure is a drugless therapy that involves the use of
                    the five elements (panchamahabutha) to restore, build up,
                    and maintain health. When nature is the healer, your healing
                    starts from within and touches the physical, mental, and
                    spiritual planes of well-being. This holistic approach
                    brings about cure in its true sense. The kind of healing
                    where your body and mind are both repaired and rejuvenated,
                    where the root cause of your ailment is treated and not
                    merely the symptoms that manifest as diseases. As much as
                    Prakriti Shakti is about finding the root cause of the
                    symptom, it is also about preventive cure.
                  </p>
                  <p className="para-desc text-muted justify">
                    In Prakriti Shakti, a physician’s role is not restricted to
                    attending to the medical needs of the patient. The doctor
                    helps you understand your body, mind, and spirit, introduces
                    you to the innate healing force, and guides you through the
                    healing process. For this process, our physicians harness
                    the elements of nature along with the teachings of yoga and
                    meditation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0"
              style={{ marginBottom: "1.3rem" }}
            >
              <hr />
            </div>
            <div className="col-lg-12 col-md-12 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h5>Principles of Nature Cure</h5>
                </div>
                <div style={cardTextStyle}>
                  <p className="text-muted justify">
                    Nature cure adopts natural measures in curing the disease.
                    It is a way of life in which man lives in harmony with
                    nature. Ayurveda has explained this beautiful concept of
                    'homologation of man and nature' in terms of
                    'Prakriti-Purusha Samya'. This system believes that Mother
                    Nature will cure all the diseases and disturbances occurring
                    in us.
                  </p>
                  <p className="text-muted justify">
                    Nature cure is also a constructive method of treatment. It
                    aims at removing the basic cause of the disease through the
                    rational use of elements freely available in nature. Thus,
                    the Nature Cure uses mud, water, air, etc., for treatment.
                    Naturopathy is a term of recent origin. But the
                    philosophical basis and methods of treatment and healing
                    used in Natural Cure are much ancient.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h5>Health & Disease</h5>
                </div>
                <div style={cardTextStyle}>
                  <p className="text-muted justify">
                    In the light of Naturopathy, health is defined as a state of
                    complete physical, mental, and social well-being and not
                    merely the absence of disease or infirmity. Health is
                    dynamic and not static. As our environment changes, our
                    bodies should adapt to the changes and maintain balance. The
                    health of any individual is determined by the following
                    factors:
                  </p>
                  <ul>
                    <li>
                      <p className="text-muted justify">
                        1. Proper intake of food
                      </p>
                    </li>
                    <li>
                      <p className="text-muted justify">
                        2. Proper management of emotions
                      </p>
                    </li>
                    <li>
                      <p className="text-muted justify">
                        3. Proper utilization of energy
                      </p>
                    </li>
                    <li>
                      <p className="text-muted justify">
                        4. Proper management of the environment
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Hidden invoice section for PDF generation */}
        <div id="invoice" style={hiddenStyle}>
          <h1>Invoice</h1>
          <p>Date: {invoiceDetails.date}</p>
          <p>Customer Name: {invoiceDetails.customerName}</p>
          <p>Address: {invoiceDetails.address}</p>
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {invoiceDetails.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.price}</td>
                  <td>{item.quantity * item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <h2>
            Total:{" "}
            {invoiceDetails.items.reduce(
              (total, item) => total + item.quantity * item.price,
              0
            )}
          </h2>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          <button onClick={generatePDF}>Download PDF</button>
        </div>
      </section>
      <ScrollTop />
      <Footer />
    </>
  );
}
