import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import bg1 from "../../assets/images/bg/blog.jpg";
import bg from "../../assets/images/bg/blog-M.jpg";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";

export default function GalleryVideo() {
  const [Blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [noData, setNoData] = useState(false); // No data available state

  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const itemsPerPage = 9;

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchBlogData = async () => {
      setLoading(true); // Set loading to true when fetching starts
      try {
        const docRef = doc(firestore, "Other", "OtherGalleryVideo");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = Object.entries(docSnap.data()).map(([key, value]) => ({
            Id: key,
            ...value,
          }));
          const sortedData = data.sort((a, b) => a.Priority - b.Priority);
          setBlog(sortedData);
          setNoData(sortedData.length === 0); // Set noData based on the length of data
        } else {
          setBlog([]);
          setNoData(true); // Set noData to true if no data exists
        }
      } catch (error) {
        console.error("Error fetching videos: ", error);
        setNoData(true); // Set noData to true in case of an error
      } finally {
        setLoading(false); // Set loading to false once fetching is complete
      }
    };

    fetchBlogData();
  }, []);

  const totalPages = Math.ceil(Blog.length / itemsPerPage);

  const currentItems = Blog.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          marginTop: isMobile ? "2rem" : "",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title text-white title-dark">
                  Gallery Videos
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  lorem lorerm lorerm
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Gallery Videos
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container">
          {loading ? (
            <div className="text-center">
              <h5>Loading...</h5> {/* Loading indicator */}
            </div>
          ) : noData ? (
            <div className="text-center">
              <h5>No Videos Available</h5> {/* No data available message */}
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <h4>Gallery Videos</h4>
              </div>
              <div className="row">
                {currentItems.map((item, index) => {
                  const embedUrl =
                    "https://www.youtube.com/embed/" + item.Link.split("=")[1];

                  return (
                    <div
                      className="col-lg-4 col-md-6 col-12 mb-4 pb-2"
                      key={index}
                    >
                      <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                        <iframe
                          className="embedded-video-16-"
                          src={embedUrl}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                        <div className="card-body p-4">
                          <h6>{item.Header}</h6>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="para-desc text-muted justify six-line-truncate"
                          >
                            {item.Description}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row text-center" style={{ marginBottom: "1rem" }}>
                <div className="col-12">
                  <ul className="pagination justify-content-center mb-0 list-unstyled">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <Link
                        className="page-link"
                        to="#"
                        onClick={handlePrev}
                        aria-label="Previous"
                      >
                        Prev
                      </Link>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          to="#"
                          onClick={() => handlePageClick(index + 1)}
                        >
                          {index + 1}
                        </Link>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <Link
                        className="page-link"
                        to="#"
                        onClick={handleNext}
                        aria-label="Next"
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
