import React from "react";
import { Route, Routes } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/scss/bootstrap.scss";
import "./assets/css/materialdesignicons.min.css";

import Home from "./pages/index/index-three";
import EventDetails from "./pages/others/eventDetails";
import AboutSwamiji from "./pages/dms_mutt/aboutswamiji";
import SwamijiDeatils from "./pages/dms_mutt/swamiji-deatils";
import Services from "./pages/dms_mutt/services";
import AwardsFelicitations from "./pages/dms_mutt/awardsfelicitations";
import Founder from "./pages/hospital/founder";
import Director from "./pages/hospital/director";
import Threapies from "./pages/hospital/threapies";
import Treatments from "./pages/hospital/treatments";
import HealthCamps from "./pages/hospital/healthcamps";
import HealthCampDeatils from "./pages/hospital/healthcampdeatils";
import ThreapiesAndTreatmentDetails from "./pages/hospital/threapies-and-treatments-details";
import AyurvedicDetails from "./pages/hospital/ayurvedic-deatils";

import Ayurveda from "./pages/hospital/ayurveda";
import Yoga from "./pages/hospital/yoga";
import Prakruti from "./pages/hospital/prakruti";
import Spiritual from "./pages/hospital/spiritual";

import Doctor from "./pages/doctor/doctor-team-three";
import DoctorDeatils from "./pages/others/doctor-details";
import Blogs from "./pages/others/blogs";
import BlogDetail from "./pages/others/blog-detail";
import Courses from "./pages/others/courses";
import CourseDeatils from "./pages/others/course-details";
import CourseRelated from "./pages/others/course-related";

import Donations from "./pages/others/donations";
import Contact from "./pages/others/contact";
import Terms from "./pages/others/terms";
import Privacy from "./pages/others/privacy";
import Error from "./pages/others/error";
import Contactform from "./components/form/Index";
import GalleryPhotos from "./pages/others/gallery-photo";
import GalleryVideo from "./pages/others/gallery-video";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/event-details/:id" element={<EventDetails />} />
      <Route path="/about-swamiji" element={<AboutSwamiji />} />
      <Route path="/swamiji-deatils" element={<SwamijiDeatils />} />
      <Route path="/services" element={<Services />} />
      <Route path="/awards-felicitations" element={<AwardsFelicitations />} />
      <Route path="/founder" element={<Founder />} />
      <Route path="/director" element={<Director />} />
      <Route path="/ayurveda" element={<Ayurveda />} />
      <Route path="/ayurvedic-details/:id" element={<AyurvedicDetails />} />
      <Route path="/threapies" element={<Threapies />} />
      <Route path="/treatments" element={<Treatments />} />
      <Route path="/health-camps" element={<HealthCamps />} />
      <Route path="/health-camps-deatils/:id" element={<HealthCampDeatils />} />
      <Route
        path="/threapies-and-treatment-Details/:id"
        element={<ThreapiesAndTreatmentDetails />}
      />
      <Route path="/yoga" element={<Yoga />} />
      <Route path="/prakruti" element={<Prakruti />} />
      <Route path="/spiritual" element={<Spiritual />} />
      <Route path="/doctors" element={<Doctor />} />
      <Route path="/doctors-details/:id" element={<DoctorDeatils />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blog-detail/:id" element={<BlogDetail />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/course-details/:id" element={<CourseDeatils />} />
      <Route path="/course-related" element={<CourseRelated />} />
      <Route path="/donations" element={<Donations />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<Error />} />
      <Route path="/contact-form" element={<Contactform />} />
      <Route path="/gallery-photos" element={<GalleryPhotos />} />
      <Route path="/gallery-videos" element={<GalleryVideo />} />
    </Routes>
  );
}

export default App;
