import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import logoLight from "../assets/images/AAA01.png";
import logo from "../assets/images/AAA.png";

import nav_logo_1 from "../assets/images/home/nav_logo_1.png";
import nav_logo_2 from "../assets/images/home/nav_logo_2.png";

import { FiCreditCard, FiPhoneCall } from "../assets/icons/vander";

export default function Navbar({ navDark, manuClass, containerClass }) {
  let [showTwo, setShowTwo] = useState(false);
  let [scroll, setScroll] = useState(false);
  let [isMenu, setisMenu] = useState(false);
  let [modal, setModal] = useState(false);

  let handleCloseTwo = () => setShowTwo(false);
  let handleShowTwo = () => setShowTwo(true);

  let [manu, setManu] = useState("");
  let location = useLocation();

  useEffect(() => {
    let current = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    setManu(current);

    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
    window.scrollTo(0, 0);
    const closeModal = () => {
      setModal(false);
    };
    document.addEventListener("mousedown", closeModal);
    return () => {
      document.removeEventListener("mousedown", closeModal);
    };
  }, []);

  let toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation").getElementsByTagName("a")
      );
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        });
      });
    }
  };

  return (
    <header
      id="topnav"
      className={`${scroll ? "nav-sticky" : ""} navigation sticky`}
    >
      <div className={containerClass} style={{ marginTop: "0.5rem" }}>
        <div>
          {navDark === true ? (
            <Link className="logo" to="/">
              <img
                src={logoLight}
                height="50"
                className="logo-light-mode"
                alt=""
              />
              <img
                src={logoLight}
                height="50"
                className="logo-dark-mode"
                alt=""
              />
            </Link>
          ) : (
            <Link className="logo" to="/">
              <span className="logo-light-mode">
                <img src={logoLight} className="l-dark" height="70" alt="" />
                <img src={logo} className="l-light" height="70" alt="" />
              </span>
              <img
                src={logoLight}
                height="22"
                className="logo-dark-mode"
                alt=""
              />
            </Link>
          )}
        </div>

        <div className="menu-extras">
          <div className="menu-item">
            <Link
              to="#"
              className={`navbar-toggle ${isMenu ? "open" : ""}`}
              id="isToggle"
              onClick={() => toggleMenu()}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <ul className="dropdowns list-inline mb-0">
          <li className="list-inline-item mb-0">
            <Link to="/donations">
              <img
                src={nav_logo_1}
                height="35"
                width="35"
                className="logo-light-mode"
                alt=""
              />
            </Link>
          </li>

          <li className="list-inline-item mb-0 ms-1">
            <Link to="/contact" className="btn btn-icon btn-pills btn-primary">
              <img
                src={nav_logo_2}
                height="35"
                width="35"
                className="logo-light-mode"
                alt=""
              />
            </Link>
          </li>
        </ul>

        <div id="navigation" style={{ display: isMenu ? "block" : "none" }}>
          <ul className={manuClass}>
            <li
              className={`${
                ["about-swamiji", "services", "awards-felicitations"].includes(
                  manu
                )
                  ? "active"
                  : ""
              } has-submenu parent-menu-item`}
            >
              <Link to="#">DMS</Link>
              <span className="menu-arrow"></span>
              <ul className="submenu">
                <li className={manu === "about-swamiji" || "" ? "active" : ""}>
                  <Link to="/about-swamiji" className="sub-menu-item">
                    About
                  </Link>
                </li>
                <li className={manu === "services" ? "active" : ""}>
                  <Link to="/services" className="sub-menu-item">
                    Services
                  </Link>
                </li>
                <li className={manu === "awards-felicitations" ? "active" : ""}>
                  <Link to="/awards-felicitations" className="sub-menu-item">
                    Awards & Felicitations
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={`${
                [
                  "founder",

                  "ayurveda",
                  "threapies",
                  "treatments",
                  "doctors",
                  "health-camps",
                ].includes(manu)
                  ? "active"
                  : ""
              } has-submenu parent-menu-item`}
            >
              <Link to="#">Hospitals</Link>
              <span className="menu-arrow"></span>
              <ul className="submenu">
                <li className={manu === "founder" ? "active" : ""}>
                  <Link to="/founder" className="sub-menu-item">
                    Trust
                  </Link>
                </li>

                <li className={manu === "ayurveda" ? "active" : ""}>
                  <Link to="/ayurveda" className="sub-menu-item">
                    Ayurveda
                  </Link>
                </li>
                <li className={manu === "threapies" || "" ? "active" : ""}>
                  <Link to="/threapies" className="sub-menu-item">
                    Ayurvedic Threapies
                  </Link>
                </li>
                <li className={manu === "treatments" || "" ? "active" : ""}>
                  <Link to="/treatments" className="sub-menu-item">
                    Ayurvedic Treatments
                  </Link>
                </li>
                <li className={manu === "doctors" ? "active" : ""}>
                  <Link to="/doctors" className="sub-menu-item">
                    Doctors
                  </Link>
                </li>
                <li className={manu === "health-camps" ? "active" : ""}>
                  <Link to="/health-camps" className="sub-menu-item">
                    Health Camps
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={`${
                ["yoga"].includes(manu) ? "active" : ""
              } has-submenu parent-menu-item`}
            >
              <Link to="/yoga">Yoga</Link>
            </li>
            <li
              className={`${
                ["prakruti"].includes(manu) ? "active" : ""
              } has-submenu parent-menu-item`}
            >
              <Link to="/prakruti">Prakruti</Link>
            </li>
            <li
              className={`${
                ["spiritual"].includes(manu) ? "active" : ""
              } has-submenu parent-menu-item`}
            >
              <Link to="/spiritual">Spiritual</Link>
            </li>

            <li
              className={`${
                ["blogs"].includes(manu) ? "active" : ""
              } has-submenu parent-menu-item`}
            >
              <Link to="/blogs">Blogs</Link>
            </li>
            <li
              className={`${
                ["courses"].includes(manu) ? "active" : ""
              } has-submenu parent-menu-item`}
            >
              <Link to="/courses">Courses</Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
