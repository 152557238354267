import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import bg1 from "../../assets/images/bg/04.jpg";
import bg from "../../assets/images/dms/Awards-M.jpg";

import ModalImage from "react-modal-image";

import award_1 from "../../assets/images/dms/Award01.png";
import award_2 from "../../assets/images/dms/Award02.png";
import award_3 from "../../assets/images/dms/Award03.png";
import award_4 from "../../assets/images/dms/Award04.png";
import award_5 from "../../assets/images/dms/Award05.png";

export default function Awardsfelicitations() {
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-left nav-light"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row  justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-2 text-white title-dark">
                  DMS Mutt ~ Awards Felicitations
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Veniam officia sapiente, odit ut distinctio enim, dolore
                  inventore suscipit!
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block mt-1">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      DMS Mutt ~ Awards Felicitations
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-4 pb-2 text-center">
                <span className="badge rounded-pill bg-soft-primary mb-3">
                  Awards Instituted
                </span>
                <h4 className="title mb-4">
                  {" "}
                  Establishment and Activities of Baby Mutt
                </h4>
              </div>
            </div>
          </div>

          <div className="row align-items-centerjustify">
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <p>
                  DMS Mutt has established several prestigious awards to honor
                  individuals who have made significant contributions in their
                  respective fields. Here are the highlights:
                </p>
                <ol type="a">
                  <li>
                    <b>Bagina Tradition:</b> A new tradition of offering
                    ‘Bagina’ to the Cauvery River during floods has been
                    instituted by the Mutt.
                  </li>
                  <li>
                    <b>Navaratri Utsav Awards:</b> During the annual Navaratri
                    Utsav, the Mutt confers the National Level "Cauvery Award"
                    and "Kayaka Seva Awards" to outstanding achievers.
                  </li>
                  <li>
                    <b>Cauvery Award:</b> This award is given to individuals who
                    have made notable contributions in their fields.
                  </li>
                  <li>
                    <b>Kayaka Seva Award:</b> This award recognizes individuals
                    for their dedicated service and achievements in their
                    respective fields.
                  </li>
                  <li>
                    <b>Kayaka Yogi Annual Award:</b> Instituted following the
                    establishment of ‘Kala College’ in Mysore, this award
                    celebrates individuals who have made exceptional
                    contributions to the arts. Each award carries a cash prize
                    of Rs. 50,000 and an award letter, reflecting the Mutt’s
                    commitment to recognizing and honoring excellence.
                  </li>
                </ol>
                <p></p>
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <img src={award_1} className="img-fluid" alt="" />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-4 pb-2 text-center">
                <span className="badge rounded-pill bg-soft-primary mb-3">
                  Awards Instituted
                </span>
                <h4 className="title mb-4">Cauvery Award Recipients</h4>
              </div>
            </div>
          </div>

          <div className="row align-items-centerjustify">
            <div className="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <ul>
                <li>
                  Sri B. Thimmegowda (2005) - Chairman, Kaveri Niravari Nigama,
                  Mysore
                </li>
                <li>Sri Nanje Gowda (2006) - Former Minister for Irrigation</li>
                <li>Justice Sri Venkatachala (2007) - Lokayuktha Karnataka</li>
                <li>
                  Smt. Salumarada Thimakka (2008) - Environmentalist, known for
                  planting trees
                </li>
                <li>
                  Sri Vishveshwara Thirtha Sri Padangalavaru (2009) - Udupi,
                  Pejavara Mutt
                </li>
                <li>Prof. N. Chidananda Murthy (2010) - Research Scholar</li>
                <li>
                  Sri Vijaya Sankeswar (2011) - President, VRL Group, Hubli
                </li>
                <li>
                  Sri K.S. Puttannaiah (2012) - President, Rajya Raitha Sangha
                </li>
                <li>Sri H.S. Doreswamy (2013) - Freedom Fighter, Bangalore</li>
                <li>No award issued (2014)</li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <ul>
                <li>Ravi Shankar Guruji (2015) - Social Reformer</li>
                <li>Sri Kiran Kumar (2016) - Chairman, ISRO, Bangalore</li>
                <li>
                  Dr. M. Mohan Alva (2017) - Chairman, Alvas Education
                  Foundation
                </li>
                <li>Dr. Sulagitthi Narasamma (2018)</li>
                <li>
                  Justice V. Shivaraj Patil (2019) - Retired Supreme Court Judge
                </li>
                <li>No award issued (2020)</li>
                <li>Harekala Hajabba (2021)</li>
                <li>Sri Ganapathi Sachidananda Swamiji (2022)</li>
                <li>
                  Dr. Vijayalakshmi Eshwarappa Balekundri (2023) - Professor
                  Emeritus, Former HOD of Pediatric Cardiology at Shri Jayadeva
                  Institute of Cardiovascular Sciences & Research, Bengaluru,
                  Popular Columnist & Philosopher
                </li>
              </ul>
            </div>
          </div>

          <div className="container" style={{ marginBottom: "2rem" }}>
            <br />
            <br />
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-3 col-sm-4">
                <ModalImage small={award_2} large={award_2} alt="" />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-4">
                <ModalImage small={award_3} large={award_3} alt="" />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-4">
                <ModalImage small={award_4} large={award_4} alt="" />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-4">
                <ModalImage small={award_5} large={award_5} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
