import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import bg1 from "../../assets/images/bg/ayurvedaTREATMENTS.jpg";
import bg from "../../assets/images/bg/ayurvedaTREATMENTS-M.jpg";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";

export default function Treatments() {
  const [Threapies, setThreapies] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetch_1 = async () => {
      try {
        const docRef = doc(firestore, "Services", "AsharamaServices_1");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          let Data = [];
          Object.entries(docSnap.data()).forEach(([key, value]) => {
            Data.push(Object.assign({ Id: key }, value));
          });
          setThreapies(Data.sort((a, b) => a.Priority - b.Priority));
        } else {
          setThreapies([]);
        }
      } catch (error) {
        setError("Error fetching treatments"); // Set error state
        console.error("Error fetching treatments: ", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetch_1();
  }, []);

  const handleViewDetails = (item) => {
    navigate(`/threapies-and-treatment-Details/${item.Id}`, {
      state: { event: item },
    });
  };

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title text-white title-dark">
                  Ayurvedic ~ Treatments
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Veniam officia sapiente, odit ut distinctio enim, dolore
                  inventore suscipit!
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block ">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      hospital ~ Ayurvedic-Treatments
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section" style={{ marginBottom: "2rem" }}>
        <div className="container">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4>Ayurvedic-Treatments</h4>
          </div>
          <div className="row align-items-center">
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5>Loading...</h5>
              </div>
            ) : error ? (
              <p>{error}</p>
            ) : Threapies.length === 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5>No data found</h5>
              </div>
            ) : (
              Threapies?.map((item, index) => (
                <div className="col-lg-6 col-md-12 mt-4 pt-2" key={index}>
                  <div className="card team border-0 rounded shadow overflow-hidden">
                    <div className="row align-items-center">
                      <div
                        className="col-md-12 card-header"
                        style={{ backgroundColor: "lightgreen" }}
                      >
                        &emsp;{item.Header}
                      </div>
                      <div className="col-md-6">
                        <div className="team-person position-relative overflow-hidden">
                          <img src={item.Image} className="img-fluid" alt="" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card-body">
                          <hr />
                          <small
                            className="text-muted speciality"
                            style={{ fontWeight: "bold" }}
                          >
                            Fee : ₹{item.Price}
                          </small>
                          <ul
                            style={{ minHeight: "60px" }}
                            className="list-unstyled mt-2 mb-0"
                          >
                            <li className="d-flex ms-0 mt-2">
                              <small className="text-muted three-line-truncate justify">
                                {item.Description}
                              </small>
                            </li>
                          </ul>
                          <hr />
                          <div className="post-meta d-flex justify-content-between mt-3">
                            <p
                              onClick={() => handleViewDetails(item)}
                              className="link"
                              style={{ cursor: "pointer" }}
                            >
                              View Details{" "}
                              <i className="mdi mdi-chevron-right align-middle"></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
