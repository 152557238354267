import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import bg1 from "../../assets/images/bg/02.jpg";
import bg from "../../assets/images/bg/founder.jpg";
import home1 from "../../assets/images/dms/dms1.png";
import home2 from "../../assets/images/dms/nagesh.png";
import Chikkathimmaiah from "../../assets/images/dms/Chikkathimmaiah.jpeg";

export default function Founder() {
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);
  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-2 text-white title-dark">
                  Hospital ~ Founder
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  Empowering the communities to achieve optimal health and
                  well-being, both physically and spiritually.
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block mt-1">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hospital ~ Founder
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <img src={home1} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h6>His Holiness</h6>
                <h4>Dr. Sri Sri Trinetra Mahantha Shivayogi Swamigalu</h4>
                <h6>Founder, Baby Mutt & DMS Chandravana Ashrama</h6>
                <hr />
                <p className="para-desc text-muted justify">
                  His Holiness Dr. Sri Sri Trinetra Mahantha Shivayogi
                  Swamigalu, the esteemed Peethadhipathigalu of Baby Mutt and
                  DMS Chandravana Ashrama, assumed the ‘Chara Pattadhikara’ -
                  mantle of pontiff as on January 28, 1999. Dr. Sri Sri Trinetra
                  Mahantha Shivayogi Swamigalu's educational journey began at
                  Tendekere Higher Primary in Chinakurali and continued at
                  Yadiyooru. He acquired profound knowledge in Sanskrit, poetry,
                  Vedas, Agama, and astrology from the Yadiyooru
                  Siddalingeshwara school.
                </p>
                <p className="para-desc text-muted justify">
                  He holds a distinguished record of passing the Agama Praveena
                  Vidwat Madhyama with distinction from Mysore Maharaja Sanskrit
                  Patashala and Vedamoola examination from Harapanahally
                  Tegginamutt Sanskrit Patashala. Additionally, he attained an
                  M.A. in Sanskrit from Karnataka Open University.
                </p>
              </div>
            </div>

            <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
              <hr />
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title ms-lg-5">
                  <h4>Dr. Nagesh N </h4>
                  <h6>BSAM , PGDYS</h6>
                  <h6>Director, Baby Mutt & DMS Chandravana Ashrama</h6>

                  <hr />
                  <h6>A Life Dedicated to Service and Healing</h6>
                  <p className="para-desc text-muted justify">
                    Dr. Nagesh N. Patil is a renowned Ayurvedic physician and
                    yogic scholar with a distinguished career spanning over four
                    decades. He is widely respected for his expertise in both
                    traditional Ayurvedic medicine and modern yogic practices,
                    and has dedicated his life to serving the community through
                    his healing touch and profound knowledge.
                  </p>
                  <h6>Early Life and Education</h6>
                  <p className="para-desc text-muted justify">
                    Born on January 21, 1957, in Chikkodi, Karnataka, India, Dr.
                    Patil's life was shaped from an early age by the values of
                    compassion and service. He pursued his medical education at
                    the Government Ayurveda Medical College (GAMC) in Mysuru,
                    graduating with a Bachelor of Ayurveda, Medicine, and
                    Surgery (BAMS) degree. His passion for yoga led him to
                    further his studies, earning a Postgraduate Diploma in Yogic
                    Science in 2021.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 order-1 order-md-2">
                <img src={home2} className="img-fluid" alt="" />
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h5>Professional Journey</h5>
                </div>

                <p className="text-muted justify">
                  Dr. Patil's illustrious career as a physician began in
                  Heggadde, where he served the community with dedication and
                  expertise. He then moved to Arasikere, Tuvaranka, Hemmaragala,
                  Uttanahalli, and Devalapura, leaving an indelible mark on the
                  lives of countless patients he treated. In 2011, Dr. Patil's
                  outstanding contributions were recognized when he was
                  appointed as the District Ayurveda Officer (DAO) for Mysuru
                  District, a position he held with distinction until 2015. His
                  tenure as DAO was marked by significant improvements in
                  healthcare delivery and accessibility, particularly in rural
                  areas.
                </p>
                <p className=" text-muted justify">
                  Following his tenure as DAO, Dr. Patil returned to GAMC
                  Mysuru, where he served as a Senior Physician, sharing his
                  vast knowledge and experience with aspiring Ayurvedic
                  practitioners. His contributions to the field of Ayurveda have
                  been widely recognized, and he has received numerous awards,
                  including the prestigious D. Devaraja Urs Mahan Sriratna
                  Award, Kannada Rajyotsava (Civic) Award, and Kayaka Seva
                  Dhurina Award. Current Endeavors
                </p>
                <p className="text-muted justify">
                  Presently, Dr. Patil serves as the Director of the Sri
                  Trinetra International Institute of Yogic Studies, where he
                  imparts his wisdom on Patanjali Yoga Sutras and Hatha Yoga,
                  enriching the lives of his students with the profound
                  teachings of yoga. His dedication to sharing his knowledge
                  extends beyond the classroom, as he also volunteers his time
                  to teach yoga at the ashrama. In addition to his yogic
                  pursuits, Dr. Patil continues to practice Ayurveda, offering
                  his healing touch to patients at the Sri Raksha Ayurvedic
                  Treatment Centre in Mysuru. His commitment to holistic healing
                  remains unwavering, as he seamlessly blends traditional
                  Ayurvedic practices with modern yogic principles to promote
                  overall well-being.
                </p>
                <p className="text-muted justify">
                  Dr. Nagesh N. Patil stands as a beacon of inspiration,
                  embodying the true spirit of service and healing. His
                  unwavering dedication to Ayurveda and yoga has transformed the
                  lives of countless individuals, and his legacy continues to
                  inspire generations to come. His journey is a testament to the
                  power of knowledge, compassion, and the unwavering pursuit of
                  excellence.
                </p>
              </div>
            </div>
            <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
              <hr />
            </div>
            <div className="col-lg-5 col-md-6">
              <img src={Chikkathimmaiah} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4>Sri Chikkathimmaiah, (Retd.) K.A.S. Officer</h4>
                <h6> M.A , B.Ed</h6>
                <h6>
                  Administrative Officer , Aadi Arogya Ashrama Ayurvedic
                  Hospital Srirangapatna
                </h6>

                <hr />
                <h6 className="mb-2 para-desc text-muted justify">
                  Work Experience
                </h6>
                <ol>
                  <li className="mb-2 para-desc text-muted justify">
                    Worked as Tahsildar for 10 years since 1985 in Kundapur,
                    Udupi and Mandya.
                  </li>
                  <li className="mb-2 para-desc text-muted justify">
                    Worked as Executive Officer for 2 years – Srirangapatna
                    Taluk Panchayath
                  </li>
                  <li className="mb-2 para-desc text-muted justify">
                    Worked as Land Acquisition Officer in Hunasur for 1 year
                  </li>

                  <li className="mb-2 para-desc text-muted justify">
                    Worked as Acquisition Officer for 6 months in MUDA, Mysore
                  </li>

                  <li className="mb-2 para-desc text-muted justify">
                    Worked as Assistant Secretary for 2 years in Zilla
                    Panchayath, Mandya
                  </li>
                  <li className="mb-2 para-desc text-muted justify">
                    Worked as Assistant Commissioner for 7 years in Pandavapura
                  </li>
                  <li className="mb-2 para-desc text-muted justify">
                    Worked as Additional District Commissioner in Coorg for 2
                    years
                  </li>
                  <li className="mb-2 para-desc text-muted justify">
                    Worked as Additional Regional Commissioner for 2 years in
                    Mysore Region
                  </li>
                  <li className="mb-2 para-desc text-muted justify">
                    Retired in 2011
                  </li>
                  <li className="mb-2 para-desc text-muted justify">
                    Worked as Chief Executive Officer since 13-09-2013 to
                    January 2024 for (10 1/2 years)
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
