import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import bg1 from "../../assets/images/bg/donation.jpg";
import Scanner from "../../assets/images/scanner.png";
import chndravanalogo from "../../assets/images/home/chndravanalogo.png";

const Donations = () => {
  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-8 d-flex flex-column">
              <div className="card login-page shadow rounded border-0 flex-grow-1">
                <div className="card-body d-flex flex-column">
                  <h4 className="text-center">
                    Your generosity transforms lives, one by one.
                  </h4>
                  <hr />
                  <div className="row justify-content-center flex-grow-1">
                    <div className="col-lg-6 col-md-6 d-flex flex-column justify-content-center">
                      <img
                        src={chndravanalogo}
                        alt="qr code"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex flex-column justify-content-center">
                      <p className="para-desc text-muted">
                        Chandravana Ashrama relies on your generous donations to
                        continue serving the community in vital ways. Their work
                        touches the lives of many who need it most. From
                        providing educational opportunities for underprivileged
                        students to offering literary resources that enrich
                        lives,
                      </p>
                      <p className="para-desc text-muted">
                        the Ashrama fosters growth and knowledge. They don't
                        stop there – their compassion extends to those facing
                        hardship. By supporting poor farmers and reaching out
                        during natural disasters, Chandravana Ashrama offers a
                        helping hand when it's needed the most. Your donation
                        fuels these vital acts of service, making a real
                        difference in the lives of countless individuals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 d-flex flex-column">
              <div className="card login-page shadow rounded border-0 flex-grow-1">
                <div className="card-body">
                  <h4 className="text-center">Account details</h4>
                  <hr />
                  <div className="row justify-content-center flex-grow-1">
                    <div>
                      <img src={Scanner} alt="qr code" className="img-fluid" />
                    </div>
                    <div className="row justify-content-center mt-3">
                      <h6 className="para-desc text-muted">
                        Bank Name : CANARA BANK
                      </h6>
                      <h6 className="para-desc text-muted">
                        Account Number : 0693101018143
                      </h6>
                      <h6 className="para-desc text-muted">
                        IFSC Code : CNRB0000693
                      </h6>
                      <h6 className="para-desc text-muted">
                        Branch : SRIRANGAPATNA
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Donations;
