import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import bg1 from "../../assets/images/bg/02.jpg";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";

const CourseDetails = () => {
  const params = useParams();
  const id = params.id;
  const [courses, setCourses] = useState([]);
  const [selectedId, setSelectedId] = useState(id);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const data = courses.find((course) => course.Id === selectedId);

  const fetchCourseDetails = async () => {
    setLoading(true);
    try {
      const docRef = doc(firestore, "Other", "Courses");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const Data = Object.entries(docSnap.data()).map(([key, value]) =>
          Object.assign({ Id: key }, value)
        );
        setCourses(Data.sort((a, b) => a.Priority - b.Priority));
      } else {
        setCourses([]);
      }
    } catch (error) {
      console.error("Error fetching Course details: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCourseDetails();
  }, []);

  const handleData = (id) => {
    setSelectedId(id);
    window.scrollTo(0, 0);
    navigate(`/course-details/${id}`);
  };

  const settings = {
    container: ".slider-range-three",
    axis: "y",
    items: 2,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 100,
    gutter: 24,
    responsive: {
      767: {
        items: 2,
      },
      320: {
        items: 1,
      },
    },
  };

  const shareToWhatsApp = () => {
    const url = window.location.href; // Get the current URL
    const message = `Check out this course: ${data?.Header}\n${data?.Description}\n\nRead more at: ${url}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <h4>Loading...</h4>
      </div>
    ); // Loading indicator
  }

  if (!data) {
    return <div>No course details available</div>;
  }

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: "center" }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title text-white title-dark">
                  DMS ~ Courses
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  Insights and practical tips empowering one to achieve mental,
                  physical, and spiritual well-being.
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      DMS ~ Courses
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-9">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h4 className="mb-4">{data?.Header ? data?.Header : ""}</h4>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={data?.Image ? data.Image : ""}
                  className="img-fluid rounded shadow"
                  alt=""
                  style={{ height: "350px" }}
                />
              </div>

              <h6>{data?.Price}</h6>
              <p
                style={{ textAlign: "justify" }}
                className="text-muted text-justify mt-4"
              >
                {data?.Description ? data?.Description : ""}
              </p>

              <p
                style={{ textAlign: "justify" }}
                className="text-muted text-justify mt-4"
              >
                {data?.Description1 ? data?.Description1 : ""}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <button
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#59AA32",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    marginTop: "1rem",
                  }}
                  onClick={() => {
                    navigate(`/contact-form`);
                  }}
                >
                  For any queries, please contact us at
                </button>
                <button
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#25D366",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    marginTop: "1rem",
                    marginLeft: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={shareToWhatsApp}
                >
                  <i
                    className="fab fa-whatsapp"
                    style={{ marginRight: "8px" }}
                  ></i>
                  Share on WhatsApp
                </button>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 mb-4">
              <div
                className="section-title"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h5>Related Courses</h5>
              </div>

              <div>
                <TinySlider settings={settings}>
                  {courses.map((item, index) => (
                    <div className="tiny-slide" key={index}>
                      <div className="card blog blog-primary border-0 shadow rounded overflow-hidden m-1 p-4">
                        <img src={item.Image} className="img-fluid" alt="" />
                        <div className="card-body p-4">
                          <Link
                            className="text-dark title h5"
                            onClick={() => handleData(item.Id)}
                          >
                            {item.Header}
                          </Link>
                          <p
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "normal",
                              marginTop: "12px",
                            }}
                          >
                            {item.Description}
                          </p>
                          <div className="post-meta d-flex justify-content-between mt-3">
                            <Link
                              className="link"
                              onClick={() => handleData(item.Id)}
                            >
                              Read More
                              <i className="mdi mdi-chevron-right align-middle"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </TinySlider>
              </div>
            </div>
            {data?.Video && (
              <div className="mt-4">
                <video controls className="w-100">
                  <source src={data?.Video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        </div>

        <div className="container mt-60 mt-60">
          <div className="row"></div>
          <div className="row">
            <div className="col-lg-12 mt-2 mb-4 pt-2"></div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
};

export default CourseDetails;
