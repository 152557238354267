import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ModalImage from "react-modal-image";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import bg1 from "../../assets/images/bg/10.jpg";
import bg from "../../assets/images/bg/yoga-M.jpg";
import Yoga01 from "../../assets/images/hospital/Yoga01.png";
import Yoga02 from "../../assets/images/yoga/yoga8.jpeg";
import Yoga03 from "../../assets/images/yoga/biochemical.jpg";
import Yoga04 from "../../assets/images/yoga/imag1.jpg";
import Yoga05 from "../../assets/images/yoga/yoga.jpeg";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  padding: "1rem",
  backgroundColor: "#fff",
};

const cardTitleStyle = {
  backgroundColor: "#f0f0f0",
  borderBottom: "1px solid #ddd",
  textAlign: "center",
  padding: "6px",
  borderRadius: "4px",
};

const cardTextStyle = {
  flexGrow: 1,
  padding: "1rem",
};

export default function Yoga() {
  const navigate = useNavigate();
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);
  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          marginTop: isMobile ? "2rem" : "",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row  justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-1 text-white title-dark">
                  Hospital ~ Yoga
                </h3>
                <p
                  className="para-desc mx-auto text-white-50"
                  style={{ display: isMobile ? "none" : "block" }}
                >
                  Yoga and meditation align seamlessly with Ayurveda's focus on
                  restoring balance and harmony within the body and mind.
                </p>
                <nav aria-label="breadcrumb" className="d-inline-block mt-0">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hospital ~ Yoga
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1rem",
            }}
          >
            <h4>Yoga</h4>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <img src={Yoga01} className="img-fluid" alt="" />
            </div>

            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>Yoga Adi Arogya Ashrama</h6>
                </div>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    The term yoga comes from a Sanskrit word "YUJ" that means
                    yoke or union. Traditionally, yoga is a method joining the
                    individual self with the Divine, Universal Spirit, or Cosmic
                    Consciousness. Physical and mental exercises are designed to
                    help achieve this goal, also called self-transcendence or
                    enlightenment. On the physical level, yoga postures, called
                    asanas, are designed to tone, strengthen, and align the
                    body. These postures are performed to make the spine supple
                    and healthy and to promote blood flow to all the organs,
                    glands, and tissues, keeping all the bodily systems healthy.
                    On the mental level, yoga uses breathing techniques
                    (pranayama) and meditation (dyana) to quiet, clarify, and
                    discipline the mind. Yoga is not a religion, but a way of
                    living with health and peace of mind as its aims.
                  </p>
                  <p className="para-desc text-muted justify">
                    In the Samadhi Chapter (Samadhi Pada) of the Yoga Sutras,
                    Patanjali gives his famous definition of Yoga: (YS 1.2):
                    Yogaś citta-vritti-nirodhaḥ
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />

        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>Goals of Yoga</h6>
                </div>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    <ol>
                      <li>Stilling the mind and gaining insight</li>
                      <li>
                        Recognize and rest in detached awareness untouched by
                        suffering
                      </li>
                      <li>
                        Achieve liberation (Moksha) from saṃsāra and duḥkha
                      </li>
                      <li>
                        Achieve unity (Aikyam) with the divine (Brahman) or with
                        one’s Self (Ātman)
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>What is Yoga Therapy?</h6>
                </div>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    Yoga therapy is a process to empower you to improve your
                    health and well-being through the practice of yoga. In other
                    words, yoga therapy is a whole-body approach to better
                    health by using the tools of yoga to meet your specific
                    physical, mental, and emotional needs and goals. You’re an
                    active participant on your path to wellness.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-4  mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>Cardinal Principles of Yoga Therapy</h6>
                </div>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    <ol>
                      <li>Relax the body</li>
                      <li>Slow down the breath</li>
                      <li>Calm the mind</li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-4  mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>Definitions of Yoga Related to Yoga Therapy</h6>
                </div>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    <ol>
                      <li>
                        A state of mastery over the senses and mind –
                        Kathopanishad
                      </li>
                      <li>
                        Equanimity of mind (Samatvam yoga uchyate) – Bhagavad
                        Gita
                      </li>
                      <li>
                        A skillful and subtle process to calm down the mind –
                        Yoga Vashistha
                      </li>
                      <li>
                        “Dukha samyoga viyogam yoga sangnitam” Disassociation
                        from the union that is pain – Bhagavad Gita
                      </li>
                      <li>
                        Yoga is said to be the oneness of breath, mind, and
                        senses and the abandonment of states of existence –
                        Maithri Upanishad
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-12 col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2rem",
              }}
            >
              <div style={cardStyle}>
                <h6>The Yogic Structural Systems</h6>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 mt-4  mb-4  pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>A.Trimurti</h6>
                </div>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    <ol>
                      <li>Vatha</li>
                      <li>Pitha</li>
                      <li>Kapha</li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 mt-4  mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>B.Non-Physical Components</h6>
                </div>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    <ol>
                      <li>Prana</li>
                      <li>Manas</li>
                      <li>Chakras</li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 mt-4  mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>C.Pancha Koshas</h6>
                </div>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    <ol>
                      <li>Annamaya Kosha</li>
                      <li>Pranamaya Kosha</li>
                      <li>Manomaya Kosha</li>
                      <li>Vignanamaya Kosha</li>
                      <li>Anandamaya Kosha</li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 mt-4  mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>D. Saptha Dhatu</h6>
                </div>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    <ol>
                      <li>Rasa</li>
                      <li>Raktha</li>
                      <li>Mamsa</li>
                      <li>Meda</li>
                      <li>Asthi</li>
                      <li>Majja</li>
                      <li>Shukra</li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-6 mb-4  pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>The Tools of Yoga Include: Ashtanga Yoga</h6>
                </div>
                <div style={cardTextStyle}>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div>
                      <p className="para-desc text-muted justify">
                        <ol>
                          <li>Yama</li>
                          <li>Niyama</li>
                          <li>Asana</li>
                          <li>Pranayama</li>
                          <li>Pratyahara</li>
                          <li>Dhyana</li>
                          <li>Dharana</li>
                          <li>Samadhi</li>
                        </ol>
                      </p>
                    </div>
                    <div>
                      <img src={Yoga02} style={{ height: "200px" }} alt="" />
                    </div>
                  </div>

                  <p className="text-muted justify">
                    Yoga therapy may be defined as the application of Yogic
                    principles to a particular person with the objective of
                    achieving a particular spiritual, psychological, or
                    physiological goal. The means employed are comprised of
                    intelligently conceived steps that include but are not
                    limited to the components of Ashtanga Yoga, which includes
                    the educational teachings of yama, niyama, asana, pranayama,
                    pratyahara, dharana, dhyana, and samadhi. Also included are
                    the application of meditation, textual study, spiritual or
                    psychological counseling, chanting, imagery, prayer, and
                    ritual to meet the needs of the individual. Yoga therapy
                    respects individual differences in age, culture, religion,
                    philosophy, occupation, and mental and physical health.
                  </p>
                  <p className="text-muted justify">
                    Yoga increases blood flow and levels of hemoglobin and red
                    blood cells which allows for more oxygen to reach the body
                    cells, enhancing their function.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>
                    The Application of Yoga Therapy is From One or More of Three
                    Perspectives:
                  </h6>
                </div>
                <div style={cardTextStyle}>
                  <p className="text-muted justify">
                    <ol>
                      <li>
                        The use of Yoga to gain a sense of power, i.e., to
                        develop muscular power, the power to concentrate, the
                        power to do difficult postures, the ability to work over
                        an extended period of time, etc. This is called the
                        application of shakti-krama.
                        <ul>
                          <li>
                            If sickness is present, it needs to be cured
                            (chikitsa).
                          </li>
                          <li>
                            If sickness is not present, protection is necessary
                            (rakshana).
                          </li>
                          <li>
                            If sickness is not present and one has learned how
                            to protect oneself, training is necessary
                            (shikshana).
                          </li>
                        </ul>
                      </li>
                      <li>
                        The use of Yoga to go beyond the physical to understand
                        what is beyond the limited sense of self; to know one’s
                        true self as the unchanging Witnessing Presence
                        (Purusha) of all that is changing (prakriti). This is
                        called the application of adhyatmika-krama.
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-12 col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2rem",
              }}
            >
              <div style={cardStyle}>
                <h6>Benefits of Yoga Therapy</h6>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>Biochemical Benefits</h6>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "1rem",
                  }}
                >
                  <img
                    src={Yoga03}
                    style={{ height: "200px", width: "200px" }}
                    alt=""
                  />
                </div>
                <div style={cardTextStyle}>
                  <p className="text-muted justify">
                    The biochemical profile improves, indicating an anti-stress
                    and antioxidant effect, important in the prevention of
                    degenerative diseases.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>Psychological Benefits</h6>
                </div>
                <div style={cardTextStyle}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={Yoga05}
                      style={{ height: "200px", width: "200px" }}
                      alt=""
                    />
                  </div>
                  <p className="text-muted justify mt-4">
                    <ol>
                      <li>Somatic and kinesthetic awareness increases</li>
                      <li>Self-acceptance and self-actualization increase</li>
                      <li>Anxiety and depression decrease</li>
                      <li>Psychomotor functions improve</li>
                      <li>
                        Cognitive functions improve – attention, concentration,
                        memory
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>Physiological Benefits</h6>
                </div>
                <div style={cardTextStyle}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={Yoga04}
                      style={{ height: "200px", width: "200px" }}
                      alt=""
                    />
                  </div>
                  <p className="text-muted justify mt-3">
                    <ol>
                      <li>Stable autonomic nervous system equilibrium</li>
                      <li>Cardiovascular efficiency increases</li>
                      <li>Respiratory efficiency increases</li>
                      <li>Gastrointestinal function normalizes</li>
                      <li>
                        Musculoskeletal flexibility and joint range of motion
                        increase
                      </li>
                      <li>Strength, resistance, endurance increase</li>
                      <li>Immunity increases</li>
                      <li>Pain decreases</li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h6>Some of the Health Benefits of Yoga Therapy Include</h6>
                </div>
                <div style={cardTextStyle}>
                  <p className="text-muted justify">
                    <ol>
                      <li>
                        Reducing physical discomfort and improving management of
                        pain
                      </li>
                      <li>
                        Helping neurological conditions such as multiple
                        sclerosis (MS), fibromyalgia, epilepsy, and stroke
                      </li>
                      <li>Easing joint pain from arthritis</li>
                      <li>
                        Reducing tension and stress and relieving headaches
                      </li>
                      <li>Helping you lose weight</li>
                      <li>
                        Boosting your mood and reducing anxiety and depression
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              <button
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#59AA32",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/contact-form`);
                }}
              >
                For any queries, please contact us at
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
