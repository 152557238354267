import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import bg1 from "../../assets/images/bg/HealthCamp.jpg";
import bg from "../../assets/images/bg/DoctorsM.jpg";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

function HealthCampDetails() {
  const params = useParams();
  const id = params.id;
  let navigate = useNavigate();
  const [Therapies, setTherapies] = useState([]);
  const data = Therapies.find((item) => item.Id === id);

  useEffect(() => {
    const fetchTherapies = async () => {
      try {
        const docRef = doc(firestore, "Services", "HealthCamps");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const Data = [];
          Object.entries(docSnap.data()).forEach(([key, value]) => {
            Data.push({ Id: key, ...value });
          });
          setTherapies(Data.sort((a, b) => a.Priority - b.Priority));
        } else {
          setTherapies([]);
        }
      } catch (error) {
        console.error("Error fetching health camps: ", error);
      }
    };
    fetchTherapies();
  }, [id]);

  const handleData = () => {
    window.scrollTo(0, 0);
  };

  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);

  const shareToWhatsApp = () => {
    const url = window.location.href; // Get the current URL
    const message = `Check out this course: ${data?.Header}\n${data?.Description}\n\nRead more at: ${url}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const settings = {
    container: ".slider-range-three",
    axis: "y",
    items: 2,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 100,
    gutter: 24,
    responsive: {
      767: {
        items: 2,
      },
      320: {
        items: 1,
      },
    },
  };

  if (!data) {
    return <div>No health camp details available</div>;
  }

  return (
    <div>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title text-white title-dark">
                  Hospital ~ Health Camps
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  Insights and practical tips empowering one to achieve mental,
                  physical, and spiritual well-being.
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hospital ~ Health Camps
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              <h4 style={{ marginTop: "1rem" }}>{data?.Header}</h4>
            </div>
            <div className="col-lg-8 col-md-8">
              <img
                src={data?.Image ? data.Image : ""}
                className="img-fluid rounded shadow"
                alt=""
                style={{ height: "350px" }}
              />

              <h5 className="mt-2">{data?.Price || ""}</h5>
              <p style={{ textAlign: "justify" }} className="text-muted mt-2">
                {data?.Description || ""}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <button
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#59AA32",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    marginTop: "1rem",
                  }}
                  onClick={() => {
                    navigate(`/contact-form`);
                  }}
                >
                  For any queries, please contact us at
                </button>
                <button
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#25D366",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    marginTop: "1rem",
                    marginLeft: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={shareToWhatsApp}
                >
                  <i
                    className="fab fa-whatsapp"
                    style={{ marginRight: "8px" }}
                  ></i>
                  Share on WhatsApp
                </button>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="slider-range-three">
                <div
                  className=" mb-4"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <h5 className="title mb-0">Related Health Camps</h5>
                </div>
                <div>
                  <TinySlider settings={settings}>
                    {Therapies.map((item) => (
                      <div className="tiny-slide" key={item.Id}>
                        <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                          <img src={item.Image} className="img-fluid" alt="" />
                          <div className="card-body p-4">
                            <Link className="text-dark title h5" to="#">
                              {item.Header}
                            </Link>
                            <p
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "normal",
                                marginTop: "12px",
                              }}
                            >
                              {item.Description}
                            </p>
                            <div className="post-meta d-flex justify-content-between mt-3">
                              <h5>{item.Price}</h5>
                              {/* <Link
                                className="link"
                                to="#"
                                onClick={() => handleData()}
                              >
                                Read More
                                <i className="mdi mdi-chevron-right align-middle"></i>
                              </Link> */}

                              <Link
                                className="link"
                                to={{
                                  pathname: `/health-camps-deatils/${item.Id}`,
                                  state: { event: item },
                                }}
                                onClick={(e) => {
                                  handleData();
                                }}
                              >
                                Read More
                                <i className="mdi mdi-chevron-right align-middle"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </TinySlider>
                </div>
              </div>
            </div>
            {data?.Video && (
              <div className="mt-4">
                <video controls className="w-100">
                  <source src={data?.Video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </div>
  );
}

export default HealthCampDetails;
