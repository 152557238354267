import React from "react";
import { Link } from "react-router-dom";

import { FiArrowRight } from "../../assets/icons/vander";

import icon01 from "../../assets/images/home/icon03.png";
import icon02 from "../../assets/images/home/icon04.png";
import icon03 from "../../assets/images/home/icon02.png";
import icon04 from "../../assets/images/home/icon01.png";

export default function FeatureOne() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-12">
          <div className="features-absolute bg-white shadow rounded overflow-hidden card-group">
            <div className="card border-0 p-4">
              <img
                src={icon01}
                height="40"
                width="40"
                className="logo-light-mode"
                alt=""
              />
              <h5 className="mt-1">Ayurveda</h5>
              <p className="text-muted mt-2">
                In Ayurveda, which is a holistic system of medicine from India,
                the balance of the three doshas Vata, Pitta, and Kapha.
              </p>
              <Link to="/ayurveda" className="text-primary">
                Read More <FiArrowRight className="align-middle" />
              </Link>
            </div>

            <div className="card border-0 bg-light p-4">
              <img
                src={icon02}
                height="40"
                width="40"
                className="logo-light-mode"
                alt=""
              />
              <h5 className="mt-1">Yoga</h5>
              <p className="text-muted mt-2">
                Yoga is a holistic practice encompassing physical, mental &
                spiritual to promote harmony between the body and mind.
              </p>
              <Link to="/yoga" className="text-primary">
                Read More <FiArrowRight className="align-middle" />
              </Link>
            </div>

            <div className="card border-0 p-4">
              <img
                src={icon03}
                height="40"
                width="40"
                className="logo-light-mode"
                alt=""
              />
              <h5 className="mt-1">Prakruti</h5>
              <p className="text-muted mt-2">
                Prakruti is key to personalized wellness, guiding natural
                treatments that harmonize mind, body, and spirit.
              </p>
              <Link to="/prakruti" className="text-primary">
                Read More <FiArrowRight className="align-middle" />
              </Link>
            </div>

            <div className="card border-0 bg-light p-4">
              <img
                src={icon04}
                height="40"
                width="40"
                className="logo-light-mode"
                alt=""
              />
              <h5 className="mt-1">Spiritual</h5>
              <p className="text-muted mt-2">
                Spiritual practices like meditation and mindfulness nurtures the
                soul, fostering inner peace and enlightenment.
              </p>
              <Link to="/spiritual" className="text-primary">
                Read More <FiArrowRight className="align-middle" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
