import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import bg1 from "../../assets/images/bg/14.jpg";
import bg from "../../assets/images/bg/DMSAdout.jpg";

import home1 from "../../assets/images/dms/dms1.png";
import home2 from "../../assets/images/dms/dms2.png";
import Home4 from "../../assets/images/dms/swamiji.png";
import Home5 from "../../assets/images/dms/Servi09.png";
import Home6 from "../../assets/images/dms/Servi11.png";
import Home7 from "../../assets/images/dms/Servi13.png";
import Home8 from "../../assets/images/dms/Service4.png";

import Home9 from "../../assets/images/dms/award_4.png";
import Home10 from "../../assets/images/dms/Service3.png";
import home11 from "../../assets/images/dms/Servi05.png";

export default function Aboutswamiji() {
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);
  const navigate = useNavigate();

  const handleDetails = () => {
    navigate(`/swamiji-deatils`);
  };
  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-left nav-light"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          marginTop: isMobile ? "2rem" : "",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row  justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-2 text-white title-dark">
                  DMS Mutt ~ About Swamiji
                </h3>
                <p
                  className="para-desc mx-auto text-white-50"
                  style={{ display: isMobile ? "none" : "block" }}
                >
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Veniam officia sapiente, odit ut distinctio enim, dolore
                  inventore suscipit!
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      DMS Mutt ~ About Swamiji
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <img src={Home4} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h6>His Holiness</h6>
                <h4>Sri Sri Maridevaru Shivayogi Mahaswamiji</h4>
                {/* <h6>Peethadhipathigalu, Baby Mutt & DMS Chandravana Ashrama</h6> */}
                <hr />
                <p className="para-desc text-muted justify">
                  His Holiness Sri Sri Maridevaru Shivayogi Mahaswamiji is truly
                  an incarnation, sacred, ideal, eternal human being who
                  dedicated his life to educate men. His unmatched energy,
                  devotion has illuminated paths of many. Even now, after his
                  demise, many of his devotees have felt his presence or power
                  through prayers which are answered. God is transcendent, yes,
                  but at the same time, he is said to be a form that represents
                  humans. He is supreme spirit, but patterned as men. Thus our
                  Seer Sri Sri Maridevaru Shivayogi Mahaswamiji was one of the
                  incarnations of God who lived among men. <br />
                </p>
                <p className="para-desc text-muted justify">
                  His birth and life history are interesting, surprising and
                  inspiring. Sharana couple Balaji and Jayamma of Sangli,
                  Maharashtra had no children for many years of their marriage.
                  They worshipped ‘Sri Durdandeshwara Mutt’ everyday with
                  devotion one night the couple dream of ‘Durdandeshwara’
                  blessing them in their dream. The very next day when they
                  visit the ‘Gaddige’ as usual, the Seer there blesses them and
                  says, their wishes would be fulfilled shortly. Thus Sri
                  Maridevaru Shivayogi Mahaswamiji was born with blessings .....
                  <span
                    style={{
                      color: "#40a900",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      handleDetails();
                    }}
                  >
                    &nbsp; Read More
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />

        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <img src={home1} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h6>His Holiness</h6>
                <h4>Dr. Sri Sri Trinetra Mahantha Shivayogi Swamigalu</h4>
                <h6>Peethadhipathigalu, Baby Mutt & DMS Chandravana Ashrama</h6>
                <hr />
                <p className="para-desc text-muted justify">
                  His Holiness Dr. Sri Sri Trinetra Mahantha Shivayogi
                  Swamigalu, the esteemed Peethadhipathigalu of Baby Mutt and
                  DMS Chandravana Ashrama, assumed the ‘Chara Pattadhikara’ -
                  mantle of pontiff as on January 28, 1999. Dr. Sri Sri Trinetra
                  Mahantha Shivayogi Swamigalu's educational journey began at
                  Tendekere Higher Primary in Chinakurali and continued at
                  Yadiyooru. He acquired profound knowledge in Sanskrit, poetry,
                  Vedas, Agama, and astrology from the Yadiyooru
                  Siddalingeshwara school.
                </p>
                <p className="para-desc text-muted justify">
                  He holds a distinguished record of passing the Agama Praveena
                  Vidwat Madhyama with distinction from Mysore Maharaja Sanskrit
                  Patashala and Vedamoola examination from Harapanahally
                  Tegginamutt Sanskrit Patashala. Additionally, he attained an
                  M.A. in Sanskrit from Karnataka Open University.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="container mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-4 pb-2 text-center">
                <span className="badge rounded-pill bg-soft-primary mb-3">
                  About
                </span>
                <h4 className="title mb-4">
                  Dr Sri Sri Trinethra Mahantha Shivayogi Swamigalu
                </h4>
              </div>
            </div>
          </div>

          <div className="row align-items-centerjustify">
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <p className="justify">
                  <b style={{ color: "black" }}>Mattadhipathi</b> - Assumed
                  ‘chara pattadhikara’ on 28-1-1999 sri kshetra Babymutt, Sri
                  Durdandeshwara Mahantha Shivayogi’s mutt
                </p>

                <p className="justify">
                  <b style={{ color: "black" }}>Educational Qualification</b> -
                  Completed primary education at Tendekere Higher Primary at
                  Chinakurali in pandavapura taluk and PUC education at
                  Yedeyooru, acquired Knowledge in Sanskrit, Poetry, veda,
                  agama, pravara astrology in yedeyooru Siddalingeshwara school
                  for veda, Agama, Sanskrit and Astrology
                </p>

                <ul>
                  <li className="justify mb-2">
                    Passed examination in Agama, Praveena Vidwat madhyama
                    Shakthi Vishitadwaitha from Mysore Maharaja Sanskrit
                    Patashala passed in distinction
                  </li>
                  <li className="justify mb-2">
                    Vedamoola examination from the Harapanahally Tegginamutt
                    Sanskrit Patashala.
                  </li>
                  <li className="justify mb-2">
                    Attained M.A.(Sanskrit) from the Karnataka Open university
                    Mother tongue - Kannada
                  </li>
                </ul>

                <h6>Projects for the development of Sri Kshetra Babymutt</h6>
                <ul>
                  <li className="justify mb-2">
                    After being made successor to the Baby Mutt in 1999. several
                    bold steps were taken to develop it and as a first step
                    Renovation of the mutt was undertaken.
                  </li>
                  <li className="justify mb-2">
                    started open Ishtalinga pooja and Shivanubhava conference in
                    the Mutt from 1999
                  </li>
                  <li className="justify mb-2">
                    Besides beautifully constructing the Gadduge(grave pit) of
                    parama poojya Sri Sri Maridevaru Shivayogi Mahaswamigalu.
                    Raja Gopura(big Arch) and prayer Hall were dedicated on the
                    first rememberance day of swamiji.
                  </li>
                  <li className="justify mb-2">
                    Installed exquisitely sculpted Ganesha idol in 2016
                  </li>
                  <li className="justify mb-2">
                    Gateway of the Sri Mutt with iconic carvings was built in
                    2016
                  </li>
                  <li className="justify mb-2">
                    Dasoha Bhavana(Dining Hall) was constructed and daily food
                    serving system was introduced
                  </li>
                  <li className="justify mb-2">
                    For Devotees visiting the mutt, Residential shelters were
                    provided
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img src={Home8} className="img-fluid" alt="" />
            </div>

            <div className="col-12">
              <hr />
            </div>

            <div
              className="col-12"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              <h5>Giant Strides in the field of Education</h5>
            </div>

            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <ul type="a">
                  <li className="justify mb-2">
                    To facilitate education for children of Baby village
                    Chinakurali and the surrounding villages. Government school
                    in the Baby village was adopted for the three successive
                    academic years and facilities were provided.
                  </li>

                  <li className="justify mb-2">
                    To fulfill the aspirations of late sri maridevaru shivayogi
                    swamiji, DMS Jnana Kuteera School from 1 st to 5 th standard
                    was set up in 2003.
                  </li>

                  <li className="justify mb-2">
                    Later it was extended from 5 th to 10 th standard and
                    educational service was offered to children of rural areas.
                  </li>
                  <li className="justify mb-2">
                    It is a matter of pride to say that the school secured cent
                    percent result in sslc in the 2015-2016 academic year.
                  </li>
                  <li className="justify mb-2">
                    Additional class rooms were constructed in the upper floor
                    last year due to increase in the strength of children
                  </li>
                  <li className="justify mb-2">
                    Free Boarding and lodging Hostel for the poor students was
                    constructed in the name of Sri Maridevaru Shivayogi Swamiji.
                  </li>
                  <li className="justify mb-2">
                    Thousands of housewives, even from other states are the
                    students of this college. Degrees like B.F.A and M.F.A are
                    confessed from this college.
                  </li>
                  <li className="justify mb-2">
                    In 2016 Durdandeshwara Astrology study center was opened and
                    classes in Veda, Agama and Astrology were started with the
                    recognition from the Sanskrit University.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <div>
                <img src={home2} className="img-fluid" alt="" />
              </div>
            </div>

            <div className="col-12 mt-4 mb-4">
              <hr />
            </div>

            <div
              className="col-12 mt-4 mb-4"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h5>
                Establishment of Chandravana Ashrama, Development and
                Achievements
              </h5>
            </div>

            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <ul>
                  <li className="justify mb-2">
                    purchased an area of 1.28 acres in 2001 and late one and a
                    half acre was added. ground breaking ceremony was done for
                    the Ashram by the eternal blessings hands of Sri maridevaru
                    Shivayogi Maha Swamigalu in 2007. The rest is all to see its
                    meteoric uprise.
                  </li>
                  <li className="justify mb-2">
                    A small cottage was built in the sprowting three and half
                    acre land for the DMS Chandravana on the bank of Cauvery in
                    Paschimavahini of srirangapatna taluk and sri swamiji Had
                    been carrying out his religions rituals and practices.
                  </li>
                  <li className="justify mb-2">
                    Sri Kashi Chandramouleswara temple, which was laying in
                    dilapidated condition on the premises of Ashram was
                    reconstructed in 2006.
                  </li>
                  <li className="justify mb-2">
                    Sri Maridevaru Shivayogi Maha Swamigalu laid the foundation
                    stone for the works of Chandravana Ashrama in 2007.
                  </li>
                  <li className="justify mb-2">
                    Shivashakti dhama was inaugurated in 2011 by the
                    centenarian, walking god Sri Shivakumara Maha Swamiji of
                    Siddaganga matt.
                  </li>
                  <li className="justify mb-2">
                    well-equipped ‘Yathri Nivas’ (Tourist guest house) to which
                    foundation laying was conducted in 2011 came up along with a
                    spacious Auditorium in 2013.
                  </li>
                  <li className="justify mb-2">
                    Apart from building DASOHA BHAVAN and a beautiful conference
                    hall in 2013 and 2014. The kitchen was modernized with Steam
                    cooking system.
                  </li>
                  <li className="justify mb-2">
                    Added attraction in this place is the artistically carved
                    stone idol of centenarian Sri late Maridevaru Shivayogi Maha
                    Swamigalu, which is built between huge stone pillars with a
                    spectacular scene of water cascade flowing over it.
                  </li>
                  <li className="justify mb-2">
                    Navagraha idols along with their wives and vehicles steal
                    the show by their tastefully sculpted carvings. Trees
                    related to each ‘Graha’ (planet) are grown.
                  </li>
                  <li className="justify mb-2">
                    Sacred ‘Kaveri Punyakoti Goshale’ (cow shelter) was set up
                    in 2014, which has given shelter to many cattle.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img src={Home9} className="img-fluid" alt="" />
            </div>

            <div className="col-lg-12 col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <ul>
                  <li className="justify mb-2">
                    ‘Dhyana Mandir’ (Meditation centre) was built in the seremic
                    environment of ashram in 2014 and same was inaugurated by
                    junior pontiff of Siddaganga Mutt.
                  </li>
                  <li className="justify mb-2">
                    construction of ‘Sopana katte’ (pyol of steps) it was built
                    for visiting devotees to offer “deeparathi” (waving of
                    heighted lamps) on the bank of Cauvery river and rest on the
                    steps.
                  </li>
                  <li className="justify mb-2">
                    Special programmes like moon light programme on every full
                    moon day, Deeparathi to Cauvery and moonlight dinner are
                    organized. The Swamiji by his own hands offer morsel of food
                    to thousands of devotees who attend the programme.
                  </li>
                  <li className="justify mb-2">
                    Religious and cultural programmes on every full moon day.
                  </li>
                  <li className="justify mb-2">
                    Navarathri Utsav and poojanushtan of the sri mutt is being
                    celebrated on a grand scale with religious priests and
                    fervons. Durgadevi idol is installed, nine day silence
                    prayer is done by the swamiji for the welfare of the
                    universe.
                  </li>
                  <li className="justify mb-2">
                    New system of filling the ‘madilu’ (upper part of the saree
                    worn by the women) of married women with ‘madilakki’ (sacred
                    blessed rice) is introduced because of which ‘Baby Mutt’ is
                    called ‘Tavaru Mutt’ (parental Home).
                  </li>
                  <li className="justify mb-2">
                    By organizing ‘samriddhi Jeevan’ (happy healthy life)
                    training camps in association with ‘jeevana sindhu
                    foundation’ from 2015, new religious vibration is created
                    among to devotees for the first time ‘kumbha mela’ (meeting
                    together of people with urns) along with conducting worship
                    and prayers, was organised at Ambigarahally.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 mt-4">
              <hr />
            </div>

            <div className="col-12 mt-4 mb-4">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5>SOCIAL ACTIVITIES</h5>
              </div>
            </div>

            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                {" "}
                <ul>
                  <li className="justify mb-2">
                    Through padayatras (foot march) the swamiji has succeeded In
                    infusing good will and revence in to the people.
                  </li>
                  <li className="justify mb-2">
                    Ware Jolige ( a square piece of cloth) to educate the people
                    abont evil effects of bad habits
                  </li>
                  <li className="justify mb-2">
                    Led an all religion peace march when communal clashes broke
                    out at Gummenhally and taught people essence of peace and
                    harmony.
                  </li>
                  <li className="justify mb-2">
                    Peace march was done by the swamiji at pandavapura, during
                    clashes between Hindus and muslims
                  </li>
                  <li className="justify mb-2">
                    Provided food materials to 2000 families of flood victims in
                    north Karnataka
                  </li>
                  <li className="justify mb-2">
                    Joined hands with farmers agitating for solution to Cauvery
                    water dispute and urging the centre and supreme court for
                    relief and participated in the dharnas protests involving
                    various religious heads there by drawing the attention of
                    the government . The swamiji has become a ‘voice’ for the
                    pains of farmers.
                  </li>
                  <li className="justify mb-2">
                    provided ‘sops’ and reliefs to the victims of Tsunami
                    cyclone by begging for alms
                  </li>
                  <li className="justify mb-2">
                    Free health check-up camps for the villagers was held on the
                    auspicious day of remembering centenarian late sri
                    maridevaru Shivayogi swamiji. Eye check up camps. Blood
                    donation camps, eye surgery ,Heart check up camps are
                    organised. Thousands of people with defective eye sight are
                    treated to restore normal sight and glasses are distributed
                    free of cost, free eye surgeries were conducted in case of
                    necessity.
                  </li>
                  <li className="justify mb-2">
                    Consultation camps on food nutrients are held to help people
                  </li>
                  <li className="justify mb-2">
                    Transformation of minds in jail prisoners was done through
                    speeches by visiting jails in Mandya, Mysore, Davanagere.
                    The jailmates were made to wear Rudrakshimala after
                    counseling
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img src={Home5} className="img-fluid" alt="" />
            </div>

            <div className="col-12 mt-4">
              <hr />
            </div>

            <div className="col-12 mt-4 mb-4">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5>SEERS AMBITIONS THOUGHTS AND SCHEMES</h5>
              </div>
            </div>

            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <ul>
                  <li className="justify mb-2">
                    Setting up of Ayurvedic Naturpathy hospital
                  </li>
                  <li className="justify mb-2">
                    Nursing plants of ayurveda Medicinal value ground breaking
                    ceremony is done to open a Centre for Research on these
                    plants.
                  </li>
                  <li className="justify mb-2">
                    To familiarize rural people with Indian culture based
                    Ayurveda system and including in them love towards it.
                  </li>
                  <li className="justify mb-2">
                    Ambhitions plan to start a School in the model of Gurukula
                    system
                  </li>
                  <li className="justify mb-2">
                    Adoption and development of villages deprived of basic
                    amenities
                  </li>
                  <li className="justify mb-2">
                    All should bow to the feet of swamiji who is carrying
                    several innovative thoughts and schemes in his head for the
                    uplipment of society and people.
                  </li>
                  <li className="justify mb-2">
                    His social concern and inner urge for the welfare of the
                    society is really commendable.
                  </li>
                  <li className="justify mb-2">
                    Young and ebullient swamiji who appears like a pole star
                    among them has created vibrations in the society in his own
                    way.
                  </li>
                  <li className="justify mb-2">
                    Sri Baby mutt with just history of two generations, has
                    achieved tremendous progress, which could have been done
                    over centuries, under the able leadership of sri swamiji who
                    is blessed by the centenarian late Sri Maridevaru Shivayogi
                    Mahaswamiji.
                  </li>
                  <li className="justify mb-2">
                    This blessing keeps the present seer busy perpetuating old
                    pontiff’s legacy having achieved, achieving and will to
                    achieve.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img src={Home6} className="img-fluid" alt="" />
            </div>

            <div className="col-12 mt-4">
              <hr />
            </div>

            <div className="col-lg-12 col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <h5>FORMATION OF THE ‘TRUST’</h5>
                </div>

                <ul>
                  <li className="justify mb-2">
                    A Trust was constituted in the interest of ensuring wellness
                    of Dharma and progress of the society
                  </li>
                  <li className="justify mb-2">
                    Sri Mahanta shivayogi vidyapeeta education institution ,
                    Mahantha Shivayogi Mutt Trust set up with the intention of
                    organizing socio educational activities
                  </li>
                  <li className="justify mb-2">
                    Sri Maridevaru Dharmika Foundation Trust with the intention
                    of initiating religious and social works. school from 1 st
                    to 10 th standard was being run, mainly focusing on
                    education with this background for the rural students.
                  </li>
                  <li className="justify mb-2">
                    A stretch of land is purchased to open Ayurvedic Naturopathy
                    Centre
                  </li>
                  <li className="justify mb-2">
                    International Yoga and Meditation centre plan will be a
                    reality in the coming days
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 mt-4">
              <hr />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1.5rem",
              }}
            >
              <h5>AWARDS INSTITUTED</h5>
            </div>

            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <ul>
                  <li className="justify mb-2">
                    A new tradition of offering ‘Bagina’ from the mutt to the
                    Cauvery river when it is in floods has begun .
                  </li>
                  <li className="justify mb-2">
                    During the Navarathri utsav on this occasion National level
                    “Cauvery Award” and ‘Kayaka seva Awards” are being conferred
                    to the achievers
                  </li>
                  <li className="justify mb-2">
                    ‘Cauvery Award’ is being conferred to those who have
                    rendered notable contribution in their fields
                  </li>
                  <li className="justify mb-2">
                    The award carried a cash of Rs.50,000 and a Award letter
                  </li>
                  <li className="justify mb-2">
                    “Kayaka Seva Award” to achievers in recognition of their
                    services in their respective fields.
                  </li>
                  <li className="justify mb-2">
                    “kayaka Yogi” Annual Award was instituted after starting
                    ‘kala college’ in Mysore
                  </li>
                </ul>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>RELIGIOUS TRIPS AND FOREIGN TOURS</h5>
                </div>
                <p className="justify">
                  The swamiji has travelled ever seventeen foreign countries.
                  propagated essence of religion with his bronze voice at the
                  Veerashaiva lingayat religious conferences held in foreign
                  countries visited Europe,Vatican city,Pisa,Swizerland, Italy,
                  France, Paris, London, Germany, China, Washington D.C.
                  Indiana, Boston, Newyork, Newjersy, Niagara, maryland,
                  America, Singapore, Thailand, Bangkok, Dubai. paid a visit to
                  holy places like Kashi,Nepal, Manasa Sarovara, Kedar, Hardwar,
                  Hrishikesh, Dwadasha Jyothirlinga, Rameshwara along with Mutt
                  devotees.
                </p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img src={Home7} className="img-fluid" alt="" />
            </div>

            <div className="col-12 mt-4">
              <hr />
            </div>

            <div className="col-lg-12 col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2rem",
                  }}
                >
                  <h5>SERVICES OF SRI MUTT TO LITERARY FIELD</h5>
                </div>
                <p className="justify">
                  To commemorate first death anniversary of centenarian
                  venerable late sri Maridevaru Shivayogi Mahaswamiji, “kayaka
                  Shilpi” souvenir was brought out.
                </p>
                <ul>
                  <li className="justify mb-2">
                    “Sadhaneya Mettilu” –compilation introduction of mutt
                    achievement
                  </li>
                  <li className="justify mb-2">
                    Collection of “Bhakti Sinchana” prayer songs. one hundred
                    and one vachanas of Basavanna, Dr.Mruthyunjaya Rumale’s
                    Lingjnana yoga,. Dr.Mooguru Madhu Dixit’s ‘Sri Durga matha’
                    Books are published.
                  </li>
                  <li className="justify mb-2">
                    ‘Nudi Nivedane’, Apoorva Gurusangama, persons, bio-data
                    books are being brought out from the DMS Chandravana
                    Prakashana.
                  </li>
                  <li className="justify mb-2">
                    Monthly Magazine “Chandravana Kampu” is being published from
                    the Sri Mutt from the last 10 months carrying articles on
                    literature-culture-religion- social reformation. It is worth
                    mentioning that thousands of readers have subscribed to the
                    magazine.
                  </li>
                  <li className="justify mb-2">
                    Chandravana Chandramouleshwara(Shiva charanamruta)
                    devotional songs,CD
                  </li>
                  <li className="justify mb-2">
                    ‘Guru Hirime’ (Documentary on the achievements of Sri
                    Kshetra Baby Mutt) VCD
                  </li>
                  <li className="justify mb-2">
                    ‘Chandra Siri’ (Baby mutt’s Sri Durdendeshwara Devotional
                    songs) CD
                  </li>
                  <li className="justify mb-2">
                    Baby mutt’s Sri Guru Kirana(documentary film)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 mt-4 mb-4">
          <hr />
        </div>

        <div className="container mt-60 ">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-4 pb-2 text-center">
                <span className="badge rounded-pill bg-soft-primary mb-3">
                  Establishment
                </span>
                <h4 className="title mb-4">
                  Establishment and Activities of Baby Mutt
                </h4>
              </div>
            </div>
          </div>

          <div className="row align-items-centerjustify">
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <p className="justify">
                  <b style={{ color: "black" }}>1999-Present:</b> Upon his
                  appointment as the successor to Baby Mutt, Dr. Sri Sri
                  Trinetra Mahantha Shivayogi Swamigalu embarked on a series of
                  bold development projects.
                </p>

                <h6>Renovation and Infrastructure Development:</h6>
                <ul>
                  <li className="justify mb-2">
                    Comprehensive renovation of Baby Mutt.
                  </li>
                  <li className="justify mb-2">
                    Construction of a beautifully designed Gadduge for Late Sri
                    Sri Maridevaru Shivayogi Mahaswamigalu, along with Raja
                    Gopura and prayer hall.
                  </li>
                  <li className="justify mb-2">
                    Installation of a sculpted Ganesha idol and the creation of
                    an iconic gateway in 2016.
                  </li>
                  <li className="justify mb-2">
                    Development of Dasoha Bhavana (dining hall) and the
                    introduction of a daily food serving system.
                  </li>
                  <li className="justify mb-2">
                    Provision of residential shelters for visiting devotees.
                  </li>
                </ul>

                <h6>Chandravana Ashrama Development:</h6>
                <ul>
                  <li className="justify mb-2">
                    Acquisition and expansion of land for the Ashrama, with
                    groundbreaking ceremonies led by revered swamis.
                  </li>
                  <li className="justify mb-2">
                    Construction of Sri Kashi Chandramouleshwara Temple and the
                    establishment of Shivashakti Dhama.
                  </li>
                  <li className="justify mb-2">
                    Creation of a well-equipped Yatri Nivas (tourist guest
                    house) and a spacious auditorium.
                  </li>
                  <li className="justify mb-2">
                    Modernization of the kitchen with a steam cooking system and
                    the building of Navagraha Vana, a sacred grove with
                    Navagraha idols and associated trees.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <img src={Home10} className="img-fluid" alt="" />
            </div>

            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h6>Religious and Cultural Initiatives:</h6>
                <ul>
                  <li className="justify mb-2">
                    Regular moonlight programs, including Deeparathi to Cauvery
                    and moonlight dinners.
                  </li>
                  <li className="justify mb-2">
                    Grand celebration of Navaratri Utsav and poojas, featuring
                    nine days of silent prayer for universal welfare.
                  </li>
                  <li className="justify mb-2">
                    Introduction of the ‘Madilu’ ritual, enhancing the spiritual
                    experience for married women.
                  </li>
                </ul>

                <h6>Community and Educational Contributions:</h6>
                <ul>
                  <li className="justify mb-2">
                    Organization of ‘Samriddhi Jeevan’ training camps in
                    collaboration with Jeevana Sindhu Foundation.
                  </li>
                  <li className="justify mb-2">
                    Hosting the first-ever Kumbha Mela at Ambigarahally.
                  </li>
                  <li className="justify mb-2">
                    Establishment of the Sri Mahanta Shivayogi Vidyapeeta and
                    Mahantha Shivayogi Mutt Trust to support socio-educational
                    activities.
                  </li>
                  <li className="justify mb-2">
                    Formation of the Sri Maridevaru Shivayogi Dharmika
                    Foundation Trust to drive religious and social initiatives.
                  </li>
                  <li className="justify mb-2">
                    Running a school from 1st to 10th standard focused on rural
                    education, with plans to introduce ITI diploma courses and
                    degrees in BFA and MFA.
                  </li>
                </ul>

                <p className="justify">
                  Under the visionary leadership of Dr. Sri Sri Trinetra
                  Mahantha Shivayogi Swamigalu, Baby Mutt and its associated
                  institutions have seen significant growth and development,
                  serving both the spiritual and educational needs of the
                  community.
                </p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img src={home11} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
