import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import bg1 from "../../assets/images/bg/02.jpg";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

const BlogHeader = ({ bgImage }) => (
  <>
    <Navbar
      manuClass="navigation-menu nav-light nav-left"
      containerClass="container"
    />
    <section
      className="bg-half-170 d-table w-100"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: "center",
      }}
    >
      <div className="bg-overlay bg-overlay-dark"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="section-title text-center">
              <h3 className="sub-title text-white title-dark">Blogs & News</h3>
              <p className="para-desc mx-auto text-white-50">
                Insights and practical tips empowering one to achieve mental,
                physical, and spiritual well-being.
              </p>
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Blogs
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="position-relative">
      <div className="shape overflow-hidden text-color-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
  </>
);

const BlogContent = ({ event }) => {
  if (!event) {
    return <div>No event details available</div>;
  }

  const embedUrl = "https://www.youtube.com/embed/" + event.Video.split("=")[1];

  const shareToWhatsApp = () => {
    const url = window.location.href; // Get the current URL
    const message = `Check out this blog: ${event.Header}\n${event.Description}\n\nRead more at: ${url}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="container" style={{ marginTop: "1rem" }}>
      <div className="row">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <h4>{event.Header}</h4>
        </div>
        <div className="col-lg-12 col-md-12 mb-4">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1rem",
            }}
          >
            <img
              src={event.Image}
              className="img-fluid rounded shadow"
              alt=""
              style={{ height: "350px" }}
            />
          </div>
          <p style={{ textAlign: "justify" }} className="text-muted mt-4">
            {event.Description}
          </p>
          <p style={{ textAlign: "justify" }} className="text-muted mt-4 mb-4">
            {event.Description1}
          </p>

          <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
            <iframe
              style={{ height: "400px" }}
              className="embedded-video-16-"
              src={embedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <button
            style={{
              padding: "10px 20px",
              backgroundColor: "#25D366",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              marginTop: "2rem",

              display: "flex",
              alignItems: "center",
            }}
            onClick={shareToWhatsApp}
          >
            <i className="fab fa-whatsapp" style={{ marginRight: "8px" }}></i>
            Share on WhatsApp
          </button>
        </div>
      </div>
    </div>
  );
};

const BlogCard = ({ item, handleData }) => (
  <div className="tiny-slide">
    <div className="card blog blog-primary border-0 shadow rounded overflow-hidden m-1">
      <img src={item.Image} className="img-fluid" alt="" />
      <div className="card-body p-4">
        <p
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
            marginTop: "12px",
          }}
        >
          {item.Description}
        </p>
        <div className="post-meta d-flex justify-content-between mt-3">
          <h5>{item.Price}</h5>
          <Link className="link" onClick={() => handleData(item.Id)}>
            Read More <i className="mdi mdi-chevron-right align-middle"></i>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

const RelatedPosts = ({ blogData, handleData }) => {
  const settings = {
    container: ".slider-range-three",
    axis: "y", // Set axis to 'y' for vertical scrolling
    items: 2,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 100,
    gutter: 24,
    responsive: {
      767: {
        items: 2,
      },
      320: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div
        className="section-title"
        style={{ marginTop: "4rem", display: "flex", justifyContent: "center" }}
      >
        <h5>Related Blogs</h5>
      </div>
      <div className="slider-range-three">
        <TinySlider settings={settings}>
          {blogData.map((item, index) => (
            <BlogCard key={index} item={item} handleData={handleData} />
          ))}
        </TinySlider>
      </div>
    </>
  );
};

export default function BlogDetail() {
  const { id } = useParams();
  const [blogData, setBlogData] = useState([]);
  const [selectedId, setSelectedId] = useState(id);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      try {
        const docRef = doc(firestore, "Other", "Blog");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = Object.entries(docSnap.data()).map(([key, value]) =>
            Object.assign({ Id: key }, value)
          );
          setBlogData(data.sort((a, b) => a.Priority - b.Priority));
        } else {
          setBlogData([]);
        }
      } catch (error) {
        console.error("Error fetching blogs: ", error);
      }
      setLoading(false);
    };

    fetchBlogs();
  }, []);

  const handleData = (id) => {
    setSelectedId(id);
    window.scrollTo(0, 0);
  };

  const data = blogData?.find((event) => event.Id === selectedId);

  return (
    <>
      <BlogHeader bgImage={bg1} />
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <h4>Loading...</h4>
              </div>
            ) : (
              <BlogContent event={data} />
            )}
          </div>
          <div className="col-lg-3 mb-4">
            <RelatedPosts blogData={blogData} handleData={handleData} />
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTop />
    </>
  );
}
