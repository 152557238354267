import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import bg1 from "../../assets/images/bg/contact.jpg";
import bg from "../../assets/images/bg/blog-M.jpg";

const Index = (props) => {
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, subject: selectedOption.value });
    setErrors({ ...errors, subject: "" });
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
      valid = false;
    }

    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
      valid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      alert("Form submitted successfully!");
      setFormData({
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: "",
      });
    }
  };

  const options = [
    { value: "Yoga", label: "Yoga" },
    { value: "Prakruti", label: "Prakruti" },
    { value: "Spiritual", label: "Spiritual" },
  ];

  return (
    <div>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          marginTop: isMobile ? "2rem" : "",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title  text-white title-dark">Contact Us</h3>
                <p className="para-desc mx-auto text-white-50">
                  Insights and practical tips empowering one to achieve mental,
                  physical, and spiritual well-being.
                </p>
                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">HOME</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div
            style={{
              maxWidth: "600px",
              margin: "0 auto",
              padding: "20px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
              marginBottom: "3rem",
              boxShadow: "0px 3px 12px lightgrey",
            }}
          >
            <h4 style={{ textAlign: "center" }}>Contact Us</h4>
            <form
              onSubmit={handleSubmit}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div style={{ marginBottom: "20px" }}>
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "6px",
                    fontSize: "16px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    ...(errors.name && { borderColor: "red" }),
                  }}
                />
                {errors.name && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.name}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <label>Phone:</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "6px",
                    fontSize: "16px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    ...(errors.phone && { borderColor: "red" }),
                  }}
                />
                {errors.phone && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.phone}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "6px",
                    fontSize: "16px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    ...(errors.email && { borderColor: "red" }),
                  }}
                />
                {errors.email && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.email}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <label>Subject:</label>
                <Select
                  options={options}
                  value={options.find(
                    (option) => option.value === formData.subject
                  )}
                  onChange={handleSelectChange}
                  styles={{
                    container: (base) => ({
                      ...base,
                      width: "100%",
                    }),
                    control: (base) => ({
                      ...base,
                      border: errors.subject
                        ? "1px solid red"
                        : "1px solid #ddd",
                    }),
                  }}
                />
                {errors.subject && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.subject}
                  </span>
                )}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <label>Message:</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "6px",
                    fontSize: "16px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    ...(errors.message && { borderColor: "red" }),
                  }}
                />
                {errors.message && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.message}
                  </span>
                )}
              </div>
              <button
                type="submit"
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#59AA32",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </div>
  );
};

export default Index;
