import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import bg1 from "../../assets/images/bg/courses.jpg";
import bg from "../../assets/images/bg/coursesM.jpg";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";

export default function Courses() {
  const navigate = useNavigate();
  const [Courses, setCourses] = useState([]);
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loading, setLoading] = useState(true); // Loading state
  const [noData, setNoData] = useState(false); // No data available state

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true); // Set loading to true when fetching starts
      try {
        const docRef = doc(firestore, "Other", "Courses");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const Data = [];
          Object.entries(docSnap.data()).forEach(([key, value]) => {
            Data.push(Object.assign({ Id: key }, value));
          });
          const sortedCourses = Data.sort((a, b) => a.Priority - b.Priority);
          setCourses(sortedCourses);
          setNoData(sortedCourses.length === 0); // Set noData based on courses length
        } else {
          setCourses([]);
          setNoData(true); // Set noData to true if no data exists
        }
      } catch (error) {
        console.error("Error fetching courses: ", error);
        setNoData(true); // Set noData to true in case of an error
      } finally {
        setLoading(false); // Set loading to false once fetching is complete
      }
    };

    fetchCourses();
  }, []);

  const handleViewDetails = (item) => {
    navigate(`/course-details/${item.Id}`, { state: { event: item } });
  };

  const titleStyle = {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 1,
    color: "#6c757d",
    textAlign: "justify",
  };

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-2 text-white title-dark">
                  DMS ~ Courses
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Veniam officia sapiente, odit ut distinctio enim, dolore
                  inventore suscipit!
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block mt-1">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      DMS ~ Courses
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container">
          {loading ? (
            <div className="text-center">
              <p>Loading...</p> {/* Loading indicator */}
            </div>
          ) : noData ? (
            <div className="text-center">
              <p>No Courses Available</p> {/* No data available message */}
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12 col-md-12 mb-2 d-flex justify-content-center">
                <h4>Courses</h4>
              </div>
              {Courses.map((item, index) => (
                <div className="col-lg-3 col-md-6 col-12 mb-4 pb-2" key={index}>
                  <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                    <img src={item.Image} className="img-fluid" alt="" />
                    <div className="card-body p-4">
                      <h6 className="one-line-truncate para-desc">
                        {item.Header}
                      </h6>
                      <p className="two-line-truncate para-desc text-muted justify">
                        {item.Description}
                      </p>
                      <div className="post-meta d-flex justify-content-between mt-3">
                        <p
                          onClick={() => handleViewDetails(item)}
                          className="link"
                          style={{ cursor: "pointer" }}
                        >
                          Read More
                          <i className="mdi mdi-chevron-right align-middle"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
}
