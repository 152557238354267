import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import bg1 from "../../assets/images/bg/01.jpg";
import bg2 from "../../assets/images/bg/13.jpeg";

import Home from "../../assets/images/bg/home.jpeg";
import HomeM from "../../assets/images/bg/aboutM.jpeg";

import home1 from "../../assets/images/home/1.png";
import home2 from "../../assets/images/home/2.png";

import FeatureOne from "../../components/features/featuresOne";
import Navbar from "../../components/navbar";

import TinySlider from "tiny-slider-react";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import { MdOutlineEventNote } from "../../assets/icons/vander";
import { contactData } from "../../data/data";

import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";

import icon01 from "../../assets/images/home/icon03.png";
import { db } from "../../Config/FbConfig";
import { collection, addDoc } from "firebase/firestore";
import { Toast, ToastContainer, Button, Form } from "react-bootstrap";

export default function IndexThree() {
  const [Notifications, setNotifications] = useState([]);
  const [Testimonials, setTestimonials] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");

  const [bgImage, setBgImage] = useState(
    window.innerWidth <= 768 ? HomeM : Home
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? HomeM : Home);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [HomeM, Home]);

  const navigate = useNavigate();

  const handleViewDetails = (item) => {
    navigate(`/event-details/${item.Id}`);
  };

  const settings = {
    container: ".client-review-slider",
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
  };

  let setting = {
    container: ".slider-range-three",
    items: 3,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 24,
    responsive: {
      992: {
        items: 3,
      },

      767: {
        items: 2,
      },

      320: {
        items: 1,
      },
    },
  };

  const Items = [
    { value: "Abhyangam", target: "therapy" },
    { value: "Sarvanga abhyangam", target: "therapy" },
    { value: "Shiro Abhyangam", target: "therapy" },
    { value: "Mukha Abhyangam", target: "therapy" },
    { value: "Pada Abhyangam", target: "therapy" },
    { value: "Upanaham", target: "therapy" },
    { value: "Utsadanam", target: "therapy" },
    { value: "Udwarthanam", target: "therapy" },
    { value: "Body massage", target: "therapy" },
    { value: "Dhara", target: "therapy" },
    { value: "Kashayadhara", target: "therapy" },
    { value: "Dhanyamladhara", target: "therapy" },
    { value: "Shirodhara/ Takradara", target: "therapy" },
    { value: "Pizhchil / Thailadhara", target: "therapy" },
    { value: "Sweda (steam)", target: "therapy" },
    { value: "Bashpa sweda", target: "therapy" },
    { value: "Nadi sweda", target: "therapy" },
    { value: "Panchakarma", target: "therapy" },
  ];

  const Items_1 = [
    { value: "Lumbar Spondylitis", target: "treatment" },
    { value: "Arthritis Spondylitis", target: "treatment" },
    { value: "Low Back Disc issues", target: "treatment" },
    { value: "Herniation", target: "treatment" },
    { value: "Spondylitis", target: "treatment" },
    { value: "Cervical Spondylitis", target: "treatment" },
    { value: "Cervical disc diseases", target: "treatment" },
    { value: "Disc Herniation", target: "treatment" },
    { value: "Myelopathies", target: "treatment" },
    { value: "Frozen Shoulder", target: "treatment" },
    { value: "Paralysis", target: "treatment" },
    { value: "Chronic Common Cold", target: "treatment" },
    { value: "Chronic Skin Diseases", target: "treatment" },
    { value: "Eye Diseases", target: "treatment" },
    { value: "Gastrointestinal Disease", target: "treatment" },
    { value: "Physically & Mentally challenged", target: "treatment" },
  ];

  useEffect(() => {
    const fetch_1 = async () => {
      try {
        const docRef = doc(firestore, "Home", "Notifications");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          var Data = [];
          Object.entries(docSnap.data()).forEach(([key, value]) => {
            Data.push(Object.assign({ Id: key }, value));
          });
          setNotifications(
            Data.sort(function (a, b) {
              return a.Priority - b.Priority;
            })
          );
        } else {
          setNotifications([]);
        }
      } catch (error) {
        console.error("Error fetching blogs: ", error);
      }
    };

    const fetch_2 = async () => {
      try {
        const docRef = doc(firestore, "Home", "Testimonials");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          var Data = [];
          Object.entries(docSnap.data()).forEach(([key, value]) => {
            Data.push(Object.assign({ Id: key }, value));
          });
          setTestimonials(
            Data.sort(function (a, b) {
              return a.Priority - b.Priority;
            })
          );
        } else {
          setTestimonials([]);
        }
      } catch (error) {
        console.error("Error fetching blogs: ", error);
      }
    };

    const fetch_3 = async () => {
      try {
        const docRef = doc(firestore, "Home", "Events");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          var Data = [];
          Object.entries(docSnap.data()).forEach(([key, value]) => {
            Data.push(Object.assign({ Id: key }, value));
          });
          setEvents(
            Data.sort(function (a, b) {
              return a.Priority - b.Priority;
            })
          );
        } else {
          setEvents([]);
        }
      } catch (error) {
        console.error("Error fetching blogs: ", error);
      }
    };

    fetch_1();
    fetch_2();
    fetch_3();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await addDoc(collection(db, "appointments"), formData);
      setFormData({
        name: "",
        email: "",
        phone: "",
        comments: "",
      });
      setToastMessage("Appointment booked successfully!");
      setToastVariant("success");
    } catch (error) {
      console.error("Error adding document: ", error);
      setToastMessage("Error booking appointment.");
      setToastVariant("danger");
    } finally {
      setShowToast(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Toast state changed:", showToast, toastMessage, toastVariant);
  }, [showToast, toastMessage, toastVariant]);

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-left nav-light"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        id="home"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="heading-title text-center">
                <h4 className="heading fw-bold text-white title-dark mt-3 mb-7">
                  Adi Arogya Ashrama Ayurvedic Hospital
                </h4>
                <p className="para-desc mx-auto text-white-50 mb-0">
                  Ancient wisdom meets modern care, providing holistic healing
                  through compassionate, personalized Ayurvedic treatments in a
                  spiritual environment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <FeatureOne />
        <br />
        <div style={{ backgroundColor: "#649b22", paddingTop: "2rem" }}>
          <marquee direction="left" style={{ whiteSpace: "nowrap" }}>
            {Notifications.length === 0
              ? "Loading..."
              : Notifications.map((data, i) => (
                  <a
                    key={i}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data.Link}
                    style={{ display: "inline-block" }}
                  >
                    <h6 style={{ color: "white", display: "inline-block" }}>
                      {data.Header}
                      {Number(i + 1) !== Notifications.length ? (
                        <span>&emsp;|&emsp;</span>
                      ) : null}
                    </h6>
                  </a>
                ))}
          </marquee>
          <hr />
        </div>
        <br />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <img src={home1} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mt-3 mb-4">
                  Adi Arogya Ashrama Ayurvedic Hospital
                </h4>
                <p className="para-desc text-muted justify">
                  Adi Arogya Ashrama is a Holistic Spiritual healing Centre of
                  DM S Chandaravana Ashrama. Ayurveda, often regarded as the
                  oldest healing science, originates from the ancient Vedic
                  culture of India, dating back over 5,000 years. The term
                  “Ayurveda” is derived from the Sanskrit words "ayuh," meaning
                  life or longevity, and "veda," meaning science or sacred
                  knowledge. Thus, Ayurveda translates to "the science of life"
                  or "the sacred knowledge of longevity." As the "Mother of All
                  Healing," Ayurveda was traditionally passed down orally from
                  master to disciple, and its principles are encapsulated in
                  ancient texts like the Charaka Samhita. This holistic system
                  emphasizes natural therapies, disease prevention, and a
                  spiritual approach to life, making it increasingly relevant in
                  today's ecological age.
                </p>
                <p className="para-desc text-muted justify">
                  The core of Ayurvedic philosophy is the concept of the five
                  elements (Panchamahabhuta)—space, air, fire, water, and
                  earth—which combine to form the three doshas: Vata, Pitta, and
                  Kapha. Each dosha governs different bodily functions and
                  aspects of health. Understanding one's unique constitution, or
                  Prakriti, based on the balance of these doshas, is fundamental
                  to Ayurvedic practice. At Chandaravana Ashram, we employ
                  various diagnostic methods to determine Prakriti, such as
                  pulse diagnosis and body type assessment.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-4 pb-2 text-center">
                <span className="badge rounded-pill bg-soft-primary mb-3">
                  Adi Arogya
                </span>
                <h4 className="title mb-4">Ayurvedic ~ Threapies</h4>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            {Items?.map((item, index) => (
              <div
                className="col-lg-3 col-md-3 col-sm-6 text-center"
                key={index}
              >
                <div
                  className="flip-card"
                  style={{
                    perspective: "1000px",
                    marginTop: "1rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="flip-card-inner"
                    style={{
                      position: "relative",
                      width: "270px", // Smaller card width
                      height: "80px", // Smaller card height
                      textAlign: "center",
                      transition: "transform 0.6s",
                      transformStyle: "preserve-3d",
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "rotateY(180deg)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "rotateY(0deg)";
                    }}
                  >
                    <div
                      className="flip-card-front"
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backfaceVisibility: "hidden",
                        backgroundColor: "#f8f9fa",
                        border: "2px solid #ddd",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "1rem",
                      }}
                    >
                      <img
                        src={icon01}
                        height="25"
                        width="25"
                        className="logo-light-mode"
                        alt=""
                        style={{ fill: "#5A67D8", marginBottom: "1rem" }} // Decent color
                      />
                      <h6
                        className="card-title mb-0"
                        style={{ fontSize: "14px", fontWeight: "600" }} // Smaller font size
                      >
                        {item.value}
                      </h6>
                    </div>
                    <div
                      className="flip-card-back"
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backfaceVisibility: "hidden",
                        backgroundColor: "#f8f9fa",
                        border: "2px solid #ddd",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "1rem",
                        transform: "rotateY(180deg)",
                      }}
                    >
                      <Link
                        to={
                          item.target === "treatment"
                            ? "/treatments"
                            : "/threapies"
                        }
                      >
                        <button
                          style={{
                            padding: "0.25rem 0.5rem", // Smaller button padding
                            backgroundColor: "#59AA32",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontSize: "12px", // Smaller font size
                          }}
                        >
                          Learn More
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-4 pb-2 text-center">
                <span className="badge rounded-pill bg-soft-primary mb-3">
                  Adi Arogya
                </span>
                <h4 className="title mb-4">Ayurvedic ~ Treatments</h4>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            {Items_1?.map((item, index) => (
              <div
                className="col-lg-3 col-md-3 col-sm-6 text-center"
                key={index}
              >
                <div
                  className="flip-card"
                  style={{
                    perspective: "1000px",
                    marginTop: "1rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="flip-card-inner"
                    style={{
                      position: "relative",
                      width: "270px", // Smaller card width
                      height: "80px", // Smaller card height
                      textAlign: "center",
                      transition: "transform 0.6s",
                      transformStyle: "preserve-3d",
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "rotateY(180deg)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "rotateY(0deg)";
                    }}
                  >
                    <div
                      className="flip-card-front"
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backfaceVisibility: "hidden",
                        backgroundColor: "#f8f9fa",
                        border: "2px solid #ddd",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "1rem",
                      }}
                    >
                      <img
                        src={icon01}
                        height="25"
                        width="25"
                        className="logo-light-mode"
                        alt=""
                        style={{ fill: "#5A67D8", marginBottom: "1rem" }} // Decent color
                      />
                      <h6
                        className="card-title mb-0"
                        style={{ fontSize: "14px", fontWeight: "600" }} // Smaller font size
                      >
                        {item.value}
                      </h6>
                    </div>
                    <div
                      className="flip-card-back"
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backfaceVisibility: "hidden",
                        backgroundColor: "#f8f9fa",
                        border: "2px solid #ddd",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "1rem",
                        transform: "rotateY(180deg)",
                      }}
                    >
                      <Link
                        to={
                          item.target === "treatment"
                            ? "/treatments"
                            : "/threapies"
                        }
                      >
                        <button
                          style={{
                            padding: "0.25rem 0.5rem", // Smaller button padding
                            backgroundColor: "#59AA32",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontSize: "12px", // Smaller font size
                          }}
                        >
                          Learn More
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="container mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-4 pb-2 text-center">
                <span className="badge rounded-pill bg-soft-primary mb-3">
                  About Mutt
                </span>
                <h4 className="title mb-4">
                  Durdundeshwara Mahantha Shivayogi Mutt
                </h4>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <p className="para-desc text-muted justify">
                  Welcome to Chandravana Ashrama, guided by the esteemed
                  leadership of Dr. Sri Sri Trinetra Mahantha Shivayogi
                  Swamigalu, Peethadhipathigalu, Baby Mutt & DMS Chandravana
                  Ashrama. Dr. Sri Sri Trinetra Mahantha Shivayogi Swamigalu
                  embarked on his spiritual journey by assuming the ‘chara
                  pattadhikara’ at Sri Durdandeshwara Mahantha Shivayogi’s mutt,
                  Sri Kshetra Baby Mutt, in 1999. His educational journey, rich
                  in Sanskrit, Veda, Agama, and astrology, culminated in an M.A.
                  in Sanskrit from Karnataka Open University. Under his
                  stewardship, Sri Kshetra Baby Mutt witnessed a remarkable
                  transformation, marked by infrastructure enhancements,
                  educational initiatives, and social welfare projects.
                </p>
                <p className="para-desc text-muted justify">
                  Driven by a vision of holistic development, Chandravana
                  Ashrama emerged as a sanctuary for spiritual seekers and a hub
                  for community welfare. With initiatives like the DMS Jnana
                  Kuteera School and the DMS Fine Arts Maha Samsthan, education
                  and cultural enrichment became paramount. The ashrama's
                  footprint expanded with the establishment of the Sri Kashi
                  Chandramouleshwara temple and the Shivashakti dhama,
                  showcasing a blend of spirituality and architectural splendor.
                  Beyond its physical presence, Chandravana Ashrama radiates a
                  spirit of service, evident in its social activities,
                  educational endeavors, and recognition through prestigious
                  awards. Dr. Sri Sri Trinetra Mahantha Shivayogi Swamigalu's
                  dynamic leadership continues to propel Chandravana Ashrama
                  towards its mission of spiritual enlightenment, societal
                  upliftment, and the preservation of timeless traditions.
                </p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img src={home2} className="img-fluid" alt="" />
            </div>
          </div>
        </div>

        <div className="container-fluid mt-100 mt-60">
          <div
            className="py-5 rounded"
            style={{ backgroundImage: `url(${bg2})` }}
          >
            <div className="container">
              <div className="row">
                <div className="offset-lg-8 offset-md-6 col-lg-4 col-md-6">
                  <div className="cta-review bg-primary shadow-md mb-lg-4 rounded p-4 py-5">
                    <div className="client-review-slider">
                      <TinySlider settings={settings}>
                        {Testimonials.map((item, index) => {
                          return (
                            <div className="tiny-slide" key={index}>
                              <p
                                className="text-white fst-italic"
                                style={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 7,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item.MSG}
                              </p>
                              <div className="d-inline-flex align-items-center">
                                <h6 className="text-light title-dark fw-normal">
                                  {item.Name}{" "}
                                  <small className="text-white-50 d-block">
                                    {item.Designation}
                                  </small>
                                </h6>
                              </div>
                            </div>
                          );
                        })}
                      </TinySlider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section justify" style={{ marginTop: "6rem" }}>
        <div className="container">
          <div className="row align-items-lg-end">
            <div className="col-md-6">
              <div className="me-xl-3">
                <div className="section-title mb-4 pb-2">
                  <MdOutlineEventNote className="text-primary h2" />
                  <h4 className="title mb-4">Embark on your journey</h4>
                  <p className="text-muted para-desc mb-0">
                    Seeking a path to deeper healing? Schedule a consultation at
                    our Ayurvedic hospital. Our personalized approach, rooted in
                    ancient wisdom, can guide you towards a future filled with
                    well-being.
                  </p>
                </div>
                {contactData.map((item, index) => {
                  let Icon = item.icon;
                  return (
                    <div
                      className="features feature-bg-primary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4"
                      key={index}
                    >
                      <Icon className="icons display-3 mb-0 text-primary" />
                      <div className="ms-3">
                        <h5 className="titles">{item.title}</h5>
                        <p className="text-muted para mb-0">{item.desc}</p>
                      </div>
                      <div className="big-icon">
                        <Icon />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="card border-0 rounded shadow p-4 ms-xl-3">
                <div className="custom-form">
                  <ToastContainer position="top-end" className="p-3">
                    <Toast
                      onClose={() => setShowToast(false)}
                      show={showToast}
                      bg={toastVariant}
                      delay={3000}
                      autohide
                    >
                      <Toast.Header>
                        <strong className="me-auto">Notification</strong>
                      </Toast.Header>
                      <Toast.Body>{toastMessage}</Toast.Body>
                    </Toast>
                  </ToastContainer>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-3">
                          <label className="form-label">
                            Patient Name <span className="text-danger">*</span>
                          </label>
                          <input
                            name="name"
                            id="name1"
                            type="text"
                            className="form-control"
                            placeholder="Patient Name :"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Your Email <span className="text-danger">*</span>
                          </label>
                          <input
                            name="email"
                            id="email"
                            type="email"
                            className="form-control"
                            placeholder="Your email :"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Your Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            name="phone"
                            id="phone"
                            type="tel"
                            className="form-control"
                            placeholder="Your Phone :"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="mb-3">
                          <label className="form-label">
                            Comments <span className="text-danger">*</span>
                          </label>
                          <textarea
                            name="comments"
                            id="comments"
                            rows="13"
                            className="form-control"
                            placeholder="Your Message :"
                            value={formData.comments}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="d-grid">
                          <button type="submit" className="btn btn-primary">
                            {loading ? "Sending..." : " Book An Appointment"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <span className="badge rounded-pill bg-soft-primary mb-3">
                  Read News
                </span>
                <h4 className="title mb-4">Latest Events & Programme</h4>
                <p className="text-muted mx-auto para-desc mb-0">
                  Stay updated with our latest events and programs designed to
                  inspire and engage. Join us to explore new opportunities and
                  experiences that enrich your journey.
                </p>
              </div>
            </div>
          </div>

          {events.length > 0 ? (
            <div className="row">
              <div className="col-lg-12 mt-4 mb-4 pt-2">
                <div className="slider-range-three">
                  <TinySlider settings={setting}>
                    {events?.map((item, index) => (
                      <div
                        className="tiny-slide"
                        key={index}
                        style={{ marginBottom: "3rem" }}
                      >
                        <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                          <img src={item.Image} className="img-fluid" alt="" />
                          <div className="card-body p-4">
                            <b className="one-line-truncate">{item.Header}</b>
                            <hr />
                            <small
                              className="two-line-truncate"
                              style={{ minHeight: "40px" }}
                            >
                              {item.Description}
                            </small>
                            <div className="post-meta d-flex justify-content-between mt-3">
                              <p
                                onClick={() => handleViewDetails(item)}
                                className="link"
                                style={{ cursor: "pointer" }}
                              >
                                View Details
                                <i className="mdi mdi-chevron-right align-middle"></i>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </TinySlider>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1rem",
              }}
            >
              <h5>NO Data Available</h5>
            </div>
          )}
        </div>
      </section>

      <Footer />
      <ScrollTop />
    </>
  );
}
