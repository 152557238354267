import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../Config/FbConfig";
import bg1 from "../../assets/images/bg/contact.jpg";
import bg from "../../assets/images/bg/contact-M.jpg";
import about from "../../assets/images/home/1.png";
import Navbar from "../../components/navbar";
import { contact } from "../../data/data";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import { Toast, ToastContainer, Button, Form } from "react-bootstrap";
import { doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

export default function Contact() {
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    comments: "",
  });

  const [usersUUID, setUsersUUID] = useState("");

  useEffect(() => {
    setUsersUUID(uuidv4());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const docRef = await setDoc(doc(db, "contacts", usersUUID), formData);
      setFormData({
        name: "",
        email: "",
        subject: "",
        comments: "",
      });
      setToastMessage("Message sent successfully!");
      setToastVariant("success");
    } catch (e) {
      console.error("Error adding document: ", e);
      setToastMessage("Error sending message.");
      setToastVariant("danger");
    } finally {
      setShowToast(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Toast state changed:", showToast, toastMessage, toastVariant);
  }, [showToast, toastMessage, toastVariant]);

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-left nav-light"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row  justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title text-white title-dark">Contact Us</h3>
                <p className="para-desc mx-auto text-white-50">
                  Great doctor if you need your family member to get effective
                  immediate assistance, emergency treatment or a simple
                  consultation.
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section>
        <div className="container" style={{ marginTop: "1.5rem" }}>
          <div className="row">
            {contact?.map((item, index) => {
              const Icon = item.icon;
              return (
                <div
                  className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"
                  key={index}
                >
                  <div className="card features feature-primary text-center border-0">
                    <div className="icon text-center rounded-md mx-auto">
                      <Icon className="h3 mb-0" />
                    </div>
                    <div className="card-body p-0 mt-3">
                      <h5>{item.title}</h5>
                      <p className="text-muted mt-3">{item.desc}</p>
                      {item?.links ? (
                        item?.links?.map((linkItem, linkIndex) => (
                          <div key={linkIndex}>
                            <Link
                              to={linkItem.link}
                              className="link"
                              target="_blank"
                            >
                              {linkItem.name}
                            </Link>
                          </div>
                        ))
                      ) : (
                        <Link to={item.link} className="link" target="_blank">
                          {item.name}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="me-lg-5">
                <img src={about} className="img-fluid" alt="" />
              </div>
            </div>

            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="custom-form rounded shadow p-4">
                <h5 className="mb-4">Get in touch!</h5>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Your Name <span className="text-danger">*</span>
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control border rounded"
                          placeholder="First Name :"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Your Email <span className="text-danger">*</span>
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control border rounded"
                          placeholder="Your email :"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Subject</label>
                        <input
                          name="subject"
                          id="subject"
                          className="form-control border rounded"
                          placeholder="Your subject :"
                          value={formData.subject}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Comments <span className="text-danger">*</span>
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          rows="4"
                          className="form-control border rounded"
                          placeholder="Your Message :"
                          value={formData.comments}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        id="submit"
                        name="send"
                        className="btn btn-primary"
                        disabled={loading}
                      >
                        {loading ? "Sending..." : "Send Message"}
                      </button>
                    </div>
                  </div>
                </form>

                <ToastContainer position="top-end" className="p-3">
                  <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    bg={toastVariant}
                    delay={3000}
                    autohide
                  >
                    <Toast.Header>
                      <strong className="me-auto">Notification</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                  </Toast>
                </ToastContainer>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ScrollTop />
      <Footer />
    </>
  );
}
