import React from "react";
import { Link } from "react-router-dom";
import logoLight from "../assets/images/logo-light.png";
import {
  FiFacebook,
  FiYoutube,
  FiInstagram,
  FiTwitter,
  FiMail,
  FiPhone,
  FiMapPin,
} from "../assets/icons/vander";

export default function Footer() {
  return (
    <>
      <footer className="">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-4 mb-0 mb-md-4 pb-0 pb-md-2">
              <Link to="#" className="logo-footer">
                <img src={logoLight} height="75" alt="" />
              </Link>
              <p className="mt-4 me-xl-5">
                Adi Arogya Ashrama is a Holistic Spiritual healing Centre of DM
                S Chandaravana Ashrama.
                <br />
                <br />
                Ayurveda, often regarded as the oldest healing science,
                originates from the ancient Vedic culture of India, dating back
                over 5,000 years.
              </p>
            </div>

            <div className="col-xl-7 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className="footer-head">DMS ~ Mutt</h5>
                  <ul className="list-unstyled footer-list mt-4">
                    <li>
                      <Link to="/about-swamiji" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> About
                        Swamiji
                      </Link>
                    </li>
                    <li>
                      <Link to="/services" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Mutt
                        services
                      </Link>
                    </li>
                    <li>
                      <Link to="/awards-felicitations" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Awards
                        Felicitations
                      </Link>
                    </li>
                    <li>
                      <Link to="/hospital" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Hospital
                      </Link>
                    </li>
                    <li>
                      <Link to="/blogs" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Blog
                      </Link>
                    </li>
                    <li>
                      <Link to="/donations" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Donations
                      </Link>
                    </li>
                    <li>
                      <Link to="/courses" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Courses
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className="footer-head">Hospital</h5>
                  <ul className="list-unstyled footer-list mt-4">
                    <li>
                      <Link to="/ayurveda" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Ayurveda
                      </Link>
                    </li>
                    <li>
                      <Link to="/yoga" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Yoga
                      </Link>
                    </li>
                    <li>
                      <Link to="/prakruti" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Prakruti
                      </Link>
                    </li>
                    <li>
                      <Link to="/spiritual" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Spiritual
                      </Link>
                    </li>
                    <li>
                      <Link to="/doctors" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Doctors
                      </Link>
                    </li>
                    <li>
                      <Link to="/gallery-photos" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Gallery
                        Images
                      </Link>
                    </li>
                    <li>
                      <Link to="/gallery-videos" className="text-foot">
                        <i className="mdi mdi-chevron-right me-1"></i> Gallery
                        videos
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className="footer-head">Contact us</h5>
                  <ul className="list-unstyled footer-list mt-4">
                    <li className="d-flex align-items-center">
                      <FiMail className="fea icon-sm text-foot align-middle" />
                      <Link
                        to="mailto:adiarogyaashrama@gmail.com"
                        className="text-foot ms-2"
                      >
                        adiarogyaashrama@gmail.com
                      </Link>
                    </li>

                    <li className="d-flex align-items-center">
                      <FiPhone className="fea icon-sm text-foot align-middle" />
                      <Link to="tel:+91 86949 71173" className="text-foot ms-2">
                        +91 86949 71173
                      </Link>
                    </li>

                    <li className="d-flex align-items-center">
                      <FiMapPin className="fea icon-sm text-foot align-middle" />
                      <Link
                        to="https://maps.app.goo.gl/VuraBHeiXQaxL16MA"
                        target="_blank"
                        className="video-play-icon text-foot ms-2"
                      >
                        View on Google map
                      </Link>
                    </li>
                  </ul>

                  <ul className="list-unstyled social-icon footer-social mb-0 mt-4">
                    <li className="list-inline-item">
                      <Link
                        to="https://fb.me/DMSChandravana"
                        target="_blank"
                        className="rounded-pill"
                      >
                        <FiFacebook className="fea icon-sm fea-social" />
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="https://www.instagram.com/chandravanaashrama/"
                        target="_blank"
                        className="rounded-pill"
                      >
                        <FiInstagram className="fea icon-sm fea-social" />
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="https://www.youtube.com/channel/UCzK18rpBDhDO1dvsYvKObBg"
                        target="_blank"
                        className="rounded-pill"
                      >
                        <FiYoutube className="fea icon-sm fea-social" />
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="https://twitter.com/ChandravanaA/"
                        target="_blank"
                        className="rounded-pill"
                      >
                        <FiTwitter className="fea icon-sm fea-social" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="pt-4 footer-bar">
            <div className="text-sm-start text-center">
              <p className="mb-0">
                {new Date().getFullYear()}© Design & Develop by{" "}
                <Link
                  to="https://c2m-edutech.com"
                  target="_blank"
                  className="text-reset"
                >
                  <b>C2M Edutech Solutions Ltd</b>
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
