import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import bg from "../../assets/images/bg/02.jpg";
import home1 from "../../assets/images/dms/nagesh.png";

export default function Director() {
  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg})`, backgroundPosition: "center" }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-4 text-white title-dark">
                  Hospital ~ Director
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  Empowering the communities to achieve optimal health and
                  well-being, both physically and spiritually.
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hospital ~ Director
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <img src={home1} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4>Dr. Nagesh N </h4>
                <h6>BSAM , PGDYS</h6>
                <hr />
                <h6>A Life Dedicated to Service and Healing</h6>
                <p className="para-desc text-muted justify">
                  Dr. Nagesh N. Patil is a renowned Ayurvedic physician and
                  yogic scholar with a distinguished career spanning over four
                  decades. He is widely respected for his expertise in both
                  traditional Ayurvedic medicine and modern yogic practices, and
                  has dedicated his life to serving the community through his
                  healing touch and profound knowledge.
                </p>
                <h6>Early Life and Education</h6>
                <p className="para-desc text-muted justify">
                  Born on January 21, 1957, in Chikkodi, Karnataka, India, Dr.
                  Patil's life was shaped from an early age by the values of
                  compassion and service. He pursued his medical education at
                  the Government Ayurveda Medical College (GAMC) in Mysuru,
                  graduating with a Bachelor of Ayurveda, Medicine, and Surgery
                  (BAMS) degree. His passion for yoga led him to further his
                  studies, earning a Postgraduate Diploma in Yogic Science in
                  2021.
                </p>
                <h6>Professional Journey</h6>
                <p className="para-desc text-muted justify">
                  Dr. Patil's illustrious career as a physician began in
                  Heggadde, where he served the community with dedication and
                  expertise. He then moved to Arasikere, Tuvaranka, Hemmaragala,
                  Uttanahalli, and Devalapura, leaving an indelible mark on the
                  lives of countless patients he treated. In 2011, Dr. Patil's
                  outstanding contributions were recognized when he was
                  appointed as the District Ayurveda Officer (DAO) for Mysuru
                  District, a position he held with distinction until 2015. His
                  tenure as DAO was marked by significant improvements in
                  healthcare delivery and accessibility, particularly in rural
                  areas.
                </p>
                <p className="para-desc text-muted justify">
                  Following his tenure as DAO, Dr. Patil returned to GAMC
                  Mysuru, where he served as a Senior Physician, sharing his
                  vast knowledge and experience with aspiring Ayurvedic
                  practitioners. His contributions to the field of Ayurveda have
                  been widely recognized, and he has received numerous awards,
                  including the prestigious D. Devaraja Urs Mahan Sriratna
                  Award, Kannada Rajyotsava (Civic) Award, and Kayaka Seva
                  Dhurina Award. Current Endeavors
                </p>
                <p className="para-desc text-muted justify">
                  Presently, Dr. Patil serves as the Director of the Sri
                  Trinetra International Institute of Yogic Studies, where he
                  imparts his wisdom on Patanjali Yoga Sutras and Hatha Yoga,
                  enriching the lives of his students with the profound
                  teachings of yoga. His dedication to sharing his knowledge
                  extends beyond the classroom, as he also volunteers his time
                  to teach yoga at the ashrama. In addition to his yogic
                  pursuits, Dr. Patil continues to practice Ayurveda, offering
                  his healing touch to patients at the Sri Raksha Ayurvedic
                  Treatment Centre in Mysuru. His commitment to holistic healing
                  remains unwavering, as he seamlessly blends traditional
                  Ayurvedic practices with modern yogic principles to promote
                  overall well-being.
                </p>
                <p className="para-desc text-muted justify">
                  Dr. Nagesh N. Patil stands as a beacon of inspiration,
                  embodying the true spirit of service and healing. His
                  unwavering dedication to Ayurveda and yoga has transformed the
                  lives of countless individuals, and his legacy continues to
                  inspire generations to come. His journey is a testament to the
                  power of knowledge, compassion, and the unwavering pursuit of
                  excellence.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
