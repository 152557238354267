import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import bg1 from "../../assets/images/bg/ayurveda.jpg";
import bg from "../../assets/images/bg/ayurveda-M.jpg";

import home1 from "../../assets/images/hospital/Ayuveda.png";

export default function Ayurveda() {
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "50vh",
          position: "relative",
        }}
      >
        <div
          className="bg-overlay"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        ></div>
        <div className="container h-100 d-flex align-items-center">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center text-white">
                <h3 className="sub-title mb-2">Hospital ~ Ayurveda</h3>
                <p className="para-desc mx-auto text-white-50">
                  Empowering communities to achieve optimal health and
                  well-being, both physically and spiritually.
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block mt-1">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hospital ~ Ayurveda
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <img
                src={home1}
                className="img-fluid rounded shadow"
                alt="Ayurveda"
              />
            </div>
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4>Ayurveda @ Adi Arogya Ashrama</h4>
                <hr />
                <p className="para-desc text-muted justify">
                  The word ‘veda’ means knowledge. The evolution of the Indian
                  art of healing and living a healthy life comes from the four
                  Vedas namely: Rig veda, Sama veda, Yajur veda, and Atharva
                  veda.
                </p>
                <p className="para-desc text-muted justify">
                  Ayurveda attained a state of reverence and is classified as
                  one of the UpaVedas - a subsection - attached to the Atharva
                  Veda. The Atharva Veda contains not only magic spells and
                  occult sciences but also Ayurveda that deals with diseases,
                  injuries, fertility, sanity, and health.
                </p>
                <p className="para-desc text-muted justify">
                  Ayurveda incorporates all forms of lifestyle in therapy. Thus
                  yoga, aroma, meditation, gems, amulets, herbs, diet,
                  astrology, color, and surgery, etc. are used comprehensively
                  in treating patients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4 className="text-center mb-4">
                Ayurveda at Adi Arogya Ashrama
              </h4>
              <p className="text-muted justify">
                At Adi Arogya Ashrama, we embrace the ancient wisdom of Ayurveda
                to offer holistic healing through personalized treatments. Our
                Ayurvedic therapies combine herbal concoctions and lifestyle
                modifications to balance the three principal energies of the
                body: Vata, Pitta, and Kapha. These energies, or doshas, are
                present in every individual in varying degrees, and the dominant
                dosha defines one's overall constitution, known as Prakriti.
              </p>
              <p className="text-muted justify">
                Diseases in Ayurveda are believed to result from imbalances in
                these doshas due to external or internal factors, such as
                dietary habits and physical activity levels. Our treatments are
                tailored to restore the balance of Vata, Pitta, and Kapha,
                ensuring they are at their optimum levels. The duration and
                method of treatment are customized based on each individual's
                Prakriti, providing a unique and effective path to wellness. At
                Adi Ayurveda Ashrama, we guide you towards a harmonious and
                healthy life through the timeless practices of Ayurveda.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <ScrollTop />
    </>
  );
}
