import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import bg from "../../assets/images/bg/services-M.jpg";
import bg1 from "../../assets/images/bg/01.jpg";
import ModalImage from "react-modal-image";

import Servi01 from "../../assets/images/dms/Servi01.png";
import Servi02 from "../../assets/images/dms/Servi02.png";
import Servi03 from "../../assets/images/dms/Servi03.png";
import Servi04 from "../../assets/images/dms/Servi04.png";
import Servi05 from "../../assets/images/dms/Servi05.png";
import Servi06 from "../../assets/images/dms/Servi06.png";
import Servi07 from "../../assets/images/dms/Servi07.png";
import Servi08 from "../../assets/images/dms/Servi08.png";
import Servi09 from "../../assets/images/dms/Servi09.png";
import Servi10 from "../../assets/images/dms/Servi10.png";
import Servi11 from "../../assets/images/dms/Servi11.png";
import Servi12 from "../../assets/images/dms/Servi12.png";
import Servi13 from "../../assets/images/dms/Service4.png";
import Servi14 from "../../assets/images/dms/Servi14.png";
import Servi15 from "../../assets/images/dms/Servi15.png";
import Servi16 from "../../assets/images/dms/Service3.png";

export default function Services() {
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-left nav-light"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          marginTop: isMobile ? "2rem" : "",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title text-white title-dark">
                  DMS Mutt ~ Services
                </h3>
                <p
                  className="para-desc mx-auto text-white-50"
                  style={{ display: isMobile ? "none" : "block" }}
                >
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Veniam officia sapiente, odit ut distinctio enim, dolore
                  inventore suscipit!
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block ">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      DMS Mutt ~ Services
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container mt-60">
          <div className="section-title mb-4 pb-2 text-center">
            <span className="badge rounded-pill bg-soft-primary mb-3">
              Literary Services
            </span>
            <h4 className="title mb-4">
              Services of Sri Mutt to the Literary Field
            </h4>
          </div>
          <div className="section-title">
            <p>
              In honor of the first death anniversary of the centenarian
              venerable Late Sri Maridevaru Shivayogi Mahaswamiji, the Sri Mutt
              has made remarkable contributions to the literary field.
            </p>
            <ol type="a">
              <li>
                <b>Kayaka Shilpi Souvenir:</b> Released to commemorate the first
                death anniversary of Late Sri Maridevaru Shivayogi Mahaswamiji.
              </li>
              <li>
                <b>Sadhaneya Mettilu:</b> A compilation highlighting the
                achievements of the Mutt.
              </li>
              <li>
                <b>Bhakti Sinchana Collection:</b> Featuring prayer songs and
                notable works such as the 101 vachanas of Basavanna, Dr.
                Mruthyunjaya Rumale's "Lingjnana Yoga," and Dr. Mugooru Madhu
                Dixit's "Sri Durga Maatha."
              </li>
              <li>
                <b>Nudi Nivedane and Apoorva Gurusangama:</b> Published by DMS
                Chandravana Prakashana, these books present the biographies and
                works of prominent figures.
              </li>
              <li>
                <b>Chandravana Kampu:</b> A monthly magazine published for the
                last eight years, featuring articles on literature, culture,
                religion, and social reformation. Thousands of readers have
                subscribed to this insightful publication.
              </li>
            </ol>
          </div>
        </div>

        <div className="container">
          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi01} large={Servi01} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi02} large={Servi02} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi03} large={Servi03} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi04} large={Servi04} alt="" />
            </div>
          </div>
        </div>

        <br />
        <hr />
        <br />

        <div className="container mt-60">
          <div className="section-title mb-4 pb-2 text-center">
            <span className="badge rounded-pill bg-soft-primary mb-3">
              Music Contributions
            </span>
            <h4 className="title mb-4">
              Services of Sri Mutt to the Music Field
            </h4>
          </div>

          <div className="section-title">
            <p>
              The Sri Mutt has also enriched the spiritual music landscape with
              several notable contributions:
            </p>
            <ol type="a">
              <li>
                <b>Chandravana Chandramouleshwara (Shiva Charanamruta):</b>{" "}
                Devotional songs dedicated to Lord Shiva.
              </li>
              <li>
                <b>Guru Hirime:</b> A documentary highlighting the achievements
                of Sri Kshetra Baby Mutt.
              </li>
              <li>
                <b>Chandra Siri:</b> Devotional songs dedicated to Sri
                Durdendeshwara.
              </li>
              <li>
                <b>Sri Guru Kirana:</b> A documentary film showcasing the life
                and contributions of the Mutt’s gurus.
              </li>
              <li>
                <b>Sri Gurudhyana Taranga:</b> Devotional songs celebrating the
                glory of Baby Mutt.
              </li>
              <li>
                <b>Meru Parva:</b> A collection of picturized devotional songs.
              </li>
              <li>
                <b>
                  Chandravana Chandramouleshwara Divya Darshana Drishyanjali:
                </b>{" "}
                A visual tribute to the divine presence and teachings of the
                Mutt.
              </li>
            </ol>
          </div>
        </div>

        <div className="container">
          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi05} large={Servi05} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi06} large={Servi06} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi07} large={Servi07} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi08} large={Servi08} alt="" />
            </div>
          </div>
        </div>

        <br />
        <hr />
        <br />

        <div className="container mt-60">
          <div className="section-title mb-4 pb-2 text-center">
            <span className="badge rounded-pill bg-soft-primary mb-3">
              Social Activities
            </span>
            <h4 className="title mb-4">Sri Mutt Social Activities</h4>
          </div>

          <div className="section-title">
            <p>
              At DMS Mutt, our dedication to social service is evident through a
              range of impactful activities aimed at fostering community welfare
              and harmony:
            </p>
            <ol type="a">
              <li>
                <b>Foot Marches for Goodwill:</b> Through padayatras (foot
                marches), Swamiji has successfully instilled goodwill and
                reverence in the community.
              </li>
              <li>
                <b>Anti Addiction Campaigns:</b> Initiated the 'Ware Jolige'
                campaign to educate people about the detrimental effects of bad
                habits.
              </li>
              <li>
                <b>Interfaith Peace Marches:</b> Led an all religion peace march
                during communal clashes at Gummanhally and a peace march in
                Pandavapura amidst Hindu - Muslim tensions, promoting peace and
                harmony.
              </li>
              <li>
                <b>Flood Relief Efforts:</b> Provided food materials to 2000
                families affected by floods in North Karnataka.
              </li>
              <li>
                <b>Support for Farmers:</b> Actively participated in protests
                alongside farmers advocating for solutions to the Cauvery water
                dispute, urging the government and Supreme Court for relief, and
                becoming a vocal supporter of farmers' rights.
              </li>
              <li>
                <b>Tsunami Relief:</b> Provided aid to victims of the Tsunami
                cyclone by collecting alms and offering support.
              </li>
              <li>
                <b>Health Camps:</b> Organized free health check up camps, eye
                check up camps, blood donation camps, and heart checkup camps on
                the auspicious day of remembering Late Sri Maridevaru Shivayogi
                Maha Swamiji. Thousands received treatment for eye defects, free
                eye surgeries, and glasses, along with consultations on food
                nutrients.
              </li>
              <li>
                <b>Prison Outreach:</b> Transformed the minds of prisoners
                through motivational speeches and counseling, visiting jails in
                Mandya, Mysore, and Davanagere. Prisoners were counseled and
                given Rudrakshimala for spiritual upliftment.
              </li>
            </ol>
            <p>
              Through these diverse social activities, DMS Mutt continues to
              make a significant difference, promoting peace, health, and well
              being in the community.
            </p>
          </div>
        </div>

        <div className="container">
          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi09} large={Servi09} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi10} large={Servi10} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi11} large={Servi11} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi12} large={Servi12} alt="" />
            </div>
          </div>
        </div>

        <br />
        <hr />
        <br />

        <div className="container mt-60">
          <div className="section-title mb-4 pb-2 text-center">
            <span className="badge rounded-pill bg-soft-primary mb-3">
              Education
            </span>
            <h4 className="title mb-4">
              Giant Strides in the Field of Education
            </h4>
          </div>

          <div className="section-title">
            <p>
              At DMS Mutt, we are committed to advancing education and
              empowering the children of Baby village, Chinakurali, and the
              surrounding areas. Here are some of our key educational
              initiatives:
            </p>
            <ol type="a">
              <li>
                <b>Adoption of Government School:</b> To support local
                education, we adopted the government school in Baby village for
                three consecutive academic years, enhancing facilities and
                resources for the students.
              </li>
              <li>
                <b>Establishment of DMS Jnana Kuteera School:</b> Fulfilling the
                vision of Late Sri Maridevaru Shivayogi Maha Swamiji, we
                established the DMS Jnana Kuteera School in 2003, initially
                offering education from 1st to 5th standard. The school was
                later extended to include classes up to the 10th standard,
                providing quality education to children from rural areas.
              </li>
              <li>
                <b>Outstanding Academic Performance:</b> Our school achieved a
                remarkable 100% pass rate in the SSLC examinations for the
                2015-2016 academic year.
              </li>
              <li>
                <b>Infrastructure Expansion:</b> Due to increasing student
                enrollment, additional classrooms were constructed on the upper
                floor last year.
              </li>
              <li>
                <b>Free Hostel Facilities:</b> We constructed a free boarding
                and lodging hostel for poor students, named in honor of Sri
                Maridevaru Shivayogi Maha Swamiji, ensuring that financial
                constraints do not hinder education.
              </li>
              <li>
                <b>DMS Fine Arts Maha Samsthana:</b> In 2005, we established the
                DMS Fine Arts Maha Samsthana, offering visual arts education
                through distance learning in collaboration with Karnataka Open
                University. This institution, the first of its kind in the
                country, provides degrees in Fine Arts (BFA and MFA), with
                thousands of housewives and students from other states enrolled.
              </li>
              <li>
                <b>Durdandeshwara Astrology Study Center:</b> In 2016, we
                inaugurated the Durdandeshwara Astrology Study Center, offering
                classes in Veda, Agama, and Astrology with recognition from
                Karnataka Sanskrit University, Bangalore.
              </li>
            </ol>
            <p>
              Through these initiatives, DMS Mutt continues to make significant
              strides in the field of education, fostering academic excellence
              and holistic development among students.
            </p>
          </div>
        </div>

        <div className="container" style={{ marginBottom: "2rem" }}>
          <br />
          <br />
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi13} large={Servi13} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi14} large={Servi14} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi15} large={Servi15} alt="" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4">
              <ModalImage small={Servi16} large={Servi16} alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
