import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import bg from "../../assets/images/bg/02.jpg";

import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";

const EventDetails = () => {
  let params = useParams();
  let id = params.id;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const data = events.find((event) => event.Id === id);

  useEffect(() => {
    const fetch_3 = async () => {
      try {
        const docRef = doc(firestore, "Home", "Events");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          var Data = [];
          Object.entries(docSnap.data()).forEach(([key, value]) => {
            Data.push(Object.assign({ Id: key }, value));
          });
          setEvents(
            Data.sort(function (a, b) {
              return a.Priority - b.Priority;
            })
          );
        } else {
          setEvents([]);
        }
      } catch (error) {
        console.error("Error fetching events: ", error);
      } finally {
        setLoading(false); // Set loading to false after fetch completes
      }
    };
    fetch_3();
  }, [id]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <h4>Loading...</h4>
      </div>
    ); // Display loading indicator
  }

  if (!data) {
    return <div>No event details available</div>;
  }

  return (
    <div>
      <Navbar
        manuClass="navigation-menu nav-left nav-light"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg})`, backgroundPosition: "center" }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title  text-white title-dark">
                  DMS Mutt ~ About Swamiji
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Veniam officia sapiente, odit ut distinctio enim, dolore
                  inventore suscipit!
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      DMS Mutt ~ About Swamiji
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <h2
        className="card-title text-center font-weight-bold"
        style={{
          fontSize: "1.5rem",
          marginTop: "2rem",
        }}
      >
        {data?.Header}
      </h2>

      <div className="container mt-4">
        <div className="card shadow-lg p-4 mb-5 bg-white rounded">
          <div className="card-body">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={data?.Image}
                className="img-fluid rounded shadow"
                alt=""
                style={{ height: "300px" }}
              />
            </div>
            <p
              className="card-text text-justify"
              style={{
                fontSize: "1rem",
                lineHeight: "1.5",
                marginTop: "2rem",
              }}
            >
              {data?.Description}
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EventDetails;
