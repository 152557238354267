import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import bg1 from "../../assets/images/bg/02.jpg";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";

function CourseRelated(props) {
  const [Courses, setCourses] = useState([]);

  const location = useLocation();
  const { event } = location.state || {};

  useEffect(() => {
    if (!event) {
      return <div>No event details available</div>;
    }
    const fetchBlogData = async () => {
      try {
        const docRef = doc(firestore, "Other", "Courses");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          var Data = [];
          Object.entries(docSnap.data()).forEach(([key, value]) => {
            Data.push(Object.assign({ Id: key }, value));
          });
          setCourses(
            Data.sort(function (a, b) {
              return a.Priority - b.Priority;
            })
          );
        } else {
          setCourses([]);
        }
      } catch (error) {
        console.error("Error fetching Courses: ", error);
      }
    };

    fetchBlogData();
  }, [event]);

  let settings = {
    container: ".slider-range-three",
    items: 3,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 24,
    responsive: {
      992: {
        items: 3,
      },
      767: {
        items: 2,
      },
      320: {
        items: 1,
      },
    },
  };

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bg1})`,
          backgroundPosition: "center",
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-4 text-white title-dark">
                  DMS ~ Courses
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  Insights and practical tips empowering one to achieve mental,
                  physical, and spiritual well-being.
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      DMS ~ Courses
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={event?.Image ? event.Image : ""}
                  className="img-fluid rounded shadow"
                  alt=""
                  style={{ height: "350px" }}
                />
              </div>
              <h5 className="mt-4">{event?.Header ? event?.Header : ""}</h5>
              <h6>{event?.Price}</h6>
              <p className="text-muted mt-4">
                {event?.Description ? event?.Description : ""}
              </p>
              <p className="text-muted mt-4">
                {event?.Description1 ? event?.Description1 : ""}
              </p>
            </div>
            {event?.Video && ( // Check if event.Video exists
              <div className="mt-4">
                <video controls className="w-100">
                  <source src={event?.Video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}

export default CourseRelated;
