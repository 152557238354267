import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBnHEPEsmv1qT16s7B8pPLVYia2Kv0NLZw",
  authDomain: "chandravana-project.firebaseapp.com",
  projectId: "chandravana-project",
  storageBucket: "chandravana-project.appspot.com",
  messagingSenderId: "846961821536",
  appId: "1:846961821536:web:2a0ab35a93b595c6e10005",
  measurementId: "G-2DJ8DPFCHY",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, firestore, storage, db };
