import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import ModalImage from "react-modal-image";
import bg1 from "../../assets/images/bg/spiritualB.jpg";
import bg from "../../assets/images/bg/spiritual-M.jpg";
import spiritual01 from "../../assets/images/hospital/spiritual01.png";
import spiritual02 from "../../assets/images/hospital/spiritual02.png";
import spiritual03 from "../../assets/images/hospital/spiritual03.png";
import spiritual04 from "../../assets/images/hospital/spiritual04.png";
import spiritual05 from "../../assets/images/hospital/spiritual05.png";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  padding: "1rem",
  backgroundColor: "#fff",
};

const cardTitleStyle = {
  backgroundColor: "#f0f0f0",
  borderBottom: "1px solid #ddd",
  textAlign: "center",
  padding: "6px",
  borderRadius: "4px",
};

const cardTextStyle = {
  flexGrow: 1,
  padding: "1rem",
};

const Spiritual = () => {
  const navigate = useNavigate();
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          marginTop: isMobile ? "2rem" : "",
        }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-1 text-white title-dark">
                  Hospital ~ Spiritual
                </h3>
                <p
                  className="para-desc mx-auto text-white-50"
                  style={{ display: isMobile ? "none" : "block" }}
                >
                  Nature's Cure Service harnesses the inherent healing power of
                  nature to promote optimal well-being in synergy with
                  traditional Ayurvedic practices.
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hospital ~ Spiritual
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      {/* Main Content */}
      <section className="section">
        <div className="container">
          <div className="row">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              <h4>Spirituality</h4>
            </div>
            <div className="col-lg-4 col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card mb-4">
                <img
                  src={spiritual01}
                  className="card-img-top"
                  alt="Spirituality"
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h5>Spirituality</h5>
                </div>
                <div style={cardTextStyle}>
                  <p className="text-muted justify">
                    Spirituality is a worldview that suggests a dimension to
                    life beyond what we experience on the sensory and physical
                    levels. In practice, this might entail religious or cultural
                    practices and beliefs surrounding a higher being, connection
                    with others and the world as a whole, and/or the pursuit of
                    self-improvement.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4 mb-4">
              <hr />
            </div>

            <div className="col-lg-12 col-md-12 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h5>Signs of Spirituality</h5>
                </div>
                <div style={cardTextStyle}>
                  <p className="text-muted justify">
                    Spirituality is not a single path or belief system. There
                    are many ways to experience spirituality and the benefits of
                    a spiritual experience. How you define spirituality will
                    vary. For some people, it's the belief in a higher power or
                    a specific religious practice.
                  </p>
                  <p className="text-muted justify">
                    For others, it may involve experiencing a sense of
                    connection to a higher state or a sense of
                    inter-connectedness with the rest of humanity and nature.
                    Some signs of spirituality can include:
                    <ol
                      className="text-muted justify"
                      style={{ marginTop: "12px" }}
                    >
                      <li>
                        Asking deep questions about topics such as suffering or
                        what happens after death
                      </li>
                      <li>Deepening connections with other people</li>
                      <li>Experiencing compassion and empathy for others</li>
                      <li>Feelings of awe and wonder</li>
                      <li>Seeking meaning and purpose</li>
                      <li>Wanting to make the world a better place</li>
                    </ol>
                  </p>
                  <p className="text-muted justify">
                    Not everyone experiences or expresses spirituality in the
                    same way. Some people may seek spiritual experiences in
                    every aspect of their lives, while others may be more likely
                    to have these feelings under specific conditions or in
                    certain locations.
                  </p>
                  <p className="text-muted justify">
                    For example, some people may be more likely to have
                    spiritual experiences in churches or other religious
                    temples, while others might have these feelings when
                    they&#39;re out enjoying nature.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div style={cardTitleStyle}>
                <h5>Types of Spirituality</h5>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTextStyle}>
                  <p className="text-muted justify">
                    There are many different types of spirituality. Some
                    examples of how people get in touch with their own
                    spirituality include
                  </p>
                  <p className="para-desc text-muted justify">
                    <ol className="text-muted justify">
                      <li> Breathwork</li>
                      <li> Meditation or quiet time</li>
                      <li> New age spirituality</li>
                      <li> Prayer</li>
                      <li> Service to their community</li>
                      <li> Spending time in nature</li>
                      <li> Spiritual retreats</li>
                      <li> Yoga</li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    Other people express their spirituality through religious
                    traditions such as:
                  </p>
                  <p className="para-desc text-muted justify">
                    <ol className="text-muted justify">
                      <li>Buddhism</li>
                      <li>Christianity</li>
                      <li>Hinduism</li>
                      <li>Humanism</li>
                      <li>Islam</li>
                      <li>Judaism</li>
                      <li>Sikhism</li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTextStyle}>
                  <p className="para-desc text-muted justify">
                    Prayer and spirituality have been linked to:
                  </p>
                  <p className="para-desc text-muted justify">
                    <ol className="text-muted justify">
                      <li>Better health</li>
                      <li>Greater psychological well-being</li>
                      <li>Less depression</li>
                      <li>Less hypertension</li>
                      <li>Less stress, even during difficult times</li>
                      <li>More positive feelings</li>
                      <li>Superior ability to handle stress</li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h5>What is spiritual health?</h5>
                </div>
                <div style={cardTextStyle}>
                  <p className="text-muted justify">
                    Just like physical and mental health, spiritual health can
                    be an important aspect of your wellbeing but it doesn’t have
                    to be connected to structured or organised practices.
                    Spiritual health often means discovering what makes you
                    truly happy and fulfilled, whether it&#39;s spending time
                    with friends and family, helping others, or exploring things
                    you’re passionate about. Taking care of your spiritual
                    health can help you live a more meaningful and
                    satisfying life.
                  </p>
                  <p className="text-muted justify">
                    Spiritual therapy aims to help a person establish contact
                    with the living God, the source of life and joy. The human
                    spirit receives its being from God and if this connection is
                    disturbed in any way, the person in whole will feel those
                    harmful consequences.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h5>Such one therapy is BHAKTI YOGA</h5>
                </div>
                <div style={cardTextStyle}>
                  <p className="text-muted justify">
                    Bhakti Yoga, often referred to as the yoga of devotion, is
                    one of the traditional paths of yoga described in ancient
                    Hindu scriptures like the Bhagavad Gita and the Bhagavata
                    Purana. At its core, Bhakti Yoga emphasizes the cultivation
                    of a deep and loving relationship with the divine, whatever
                    form or concept it may take for the practitioner. It is a
                    path of surrender, where the ego dissolves in love, and the
                    individual soul merges with the universal consciousness.
                  </p>
                  <p className="text-muted justify">
                    Bhakti Yoga encompasses various forms and practices, each
                    tailored to suit the unique temperament and inclinations of
                    practitioners. Some common types of Bhakti Yoga include:
                  </p>
                  <p className="text-muted justify">
                    <ol className="card-text" style={cardTextStyle}>
                      <li>
                        Singing and Chanting (Kirtan): Devotional singing and
                        chanting of sacred mantras and hymns.
                      </li>
                      <li>
                        Prayer and Worship (Puja): Ritualistic worship of
                        deities through prayers, offerings, and ceremonies.
                      </li>
                      <li>
                        Satsang: Gathering with like-minded individuals for
                        spiritual discourse, chanting, and meditation.
                      </li>
                      <li>
                        Seva (Selfless Service): Engaging in acts of selfless
                        service and compassion towards others as an expression
                        of devotion.
                      </li>
                      <li>
                        Guru Bhakti: Reverence and devotion towards one&#39;s
                        spiritual teacher or guru, considering them as a
                        manifestation of the divine.
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4 mb-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card" style={cardStyle}>
                <div style={cardTitleStyle}>
                  <h5>Astounding Benefits Of Bhakti Yoga</h5>
                </div>
                <div style={cardTextStyle}>
                  <p className="text-muted justify">
                    Bhakti Yoga offers numerous benefits for the body, mind, and
                    soul, including
                  </p>
                  <h6>Emotional Healing</h6>
                  <p className="text-muted justify">
                    It cultivates feelings of love, compassion, and gratitude,
                    promoting emotional well-being.
                  </p>
                  <h6>Reduces Stress</h6>
                  <p className="text-muted justify">
                    Engaging in devotional practices fosters surrender and trust
                    in a higher power, helping to alleviate stress, anxiety, and
                    emotional turmoil
                  </p>
                  <h6>Spiritual Growth</h6>
                  <p className="text-muted justify">
                    Bhakti Yoga deepens one&#39;s connection with the divine,
                    facilitating spiritual growth, self-discovery, and a
                    profound sense of purpose and meaning in life.
                  </p>
                  <h6>Promotes Heart Health</h6>
                  <p className="text-muted justify">
                    The practice of Bhakti Yoga is believed to have a positive
                    impact on cardiovascular health by reducing stress,
                    promoting relaxation, and fostering a sense of inner peace.
                  </p>
                  <h6>Connection and Unity</h6>
                  <p className="text-muted justify">
                    This practice strengthens the sense of connection and unity
                    with all beings, fostering harmony and understanding.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1rem",
            }}
          >
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#59AA32",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/contact-form`);
              }}
            >
              For any queries, please contact us at
            </button>
          </div>

          <div className="row mt-4 justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div className="card" style={cardStyle}>
                <ModalImage small={spiritual02} large={spiritual02} alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div className="card" style={cardStyle}>
                <ModalImage small={spiritual03} large={spiritual03} alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div className="card" style={cardStyle}>
                <ModalImage small={spiritual04} large={spiritual04} alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div className="card" style={cardStyle}>
                <ModalImage small={spiritual05} large={spiritual05} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <ScrollTop />
    </>
  );
};

export default Spiritual;
