import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

import bg1 from "../../assets/images/bg/Doctors.jpg";
import bg from "../../assets/images/bg/DoctorsM.jpg";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import { firestore } from "../../Config/FbConfig";

export default function DoctorDetails() {
  let params = useParams();
  let id = params.id;
  let navigate = useNavigate();

  const [Doctors, setDoctors] = useState([]);

  const data = Doctors.find((event) => event.Id === id);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const docRef = doc(firestore, "Other", "DoctorProfile");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const Data = [];
          Object.entries(docSnap.data()).forEach(([key, value]) => {
            Data.push({ Id: key, ...value });
          });
          setDoctors(Data.sort((a, b) => a.Priority - b.Priority));
        } else {
          setDoctors([]);
        }
      } catch (error) {
        console.error("Error fetching doctor profiles: ", error);
      }
    };
    fetchDoctors();
  }, []);

  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);

  const handleData = (item) => {
    window.scrollTo(0, 0);
    navigate(`/doctors-details/${item.Id}`);
  };

  const settings = {
    container: ".slider-range-three",
    axis: "y", // Set axis to 'y' for vertical scrolling
    items: 2,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 100,
    gutter: 24,
    responsive: {
      767: {
        items: 2,
      },
      320: {
        items: 1,
      },
    },
  };

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title text-white title-dark">
                  Doctors team
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  Great doctor if you need your family member to get effective
                  immediate assistance, emergency treatment or a simple
                  consultation.
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Doctors team
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              <h4 style={{ marginTop: "1rem" }}>{data?.Name}</h4>
            </div>
            <div className="col-lg-8 col-md-8">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={data?.Image || ""}
                  className="img-fluid rounded shadow"
                  alt=""
                  style={{ height: "350px" }}
                />
              </div>

              <h6>{data?.Qualification}</h6>
              <h6>{data?.Experience}</h6>
              <p className="text-muted mt-4">{data?.Description || ""}</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <button
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#59AA32",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    marginTop: "1rem",
                  }}
                  onClick={() => {
                    navigate(`/contact-form`);
                  }}
                >
                  For any queries, please contact us at
                </button>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="slider-range-three">
                <div className="section-title mb-4">
                  <h4 className="title mb-0">Related Doctors</h4>
                </div>
                <TinySlider settings={settings}>
                  {Doctors.map((item, index) => (
                    <div className="tiny-slide" key={index}>
                      <div className="card blog blog-primary border-0 shadow rounded overflow-hidden m-1">
                        <img src={item.Image} className="img-fluid" alt="" />
                        <div className="card-body p-4">
                          <Link
                            className="text-dark title h5"
                            to={`/doctor-details/${item.Id}`}
                          >
                            {item.Name}
                          </Link>
                          <h6 style={{ marginTop: "6px" }}>
                            {item.Experience}
                          </h6>
                          <h6 style={{ marginTop: "3px" }}>
                            {item.Qualification}
                          </h6>
                          <p
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 2, // Number of lines to show
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "normal",
                              marginTop: "12px",
                            }}
                          >
                            {item.Description}
                          </p>
                          <div className="post-meta d-flex justify-content-between mt-3">
                            <h5>{item.Price}</h5>
                            <Link
                              className="link"
                              to={`/doctors-details/${item.Id}`}
                              onClick={() => handleData(item)}
                            >
                              Read More
                              <i className="mdi mdi-chevron-right align-middle"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </TinySlider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
