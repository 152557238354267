import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bg1 from "../../assets/images/bg/Doctors.jpg";
import bg from "../../assets/images/bg/DoctorsM.jpg";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";

export default function DoctorTeamThree() {
  const [Doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [bgImage, setBgImage] = useState(window.innerWidth <= 768 ? bg : bg1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const navigate = useNavigate();

  const handleViewDetails = (item) => {
    navigate(`/doctors-details/${item.Id}`, { state: { event: item } });
  };

  useEffect(() => {
    const handleResize = () => {
      setBgImage(window.innerWidth <= 768 ? bg : bg1);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bg, bg1]);

  useEffect(() => {
    const fetch_1 = async () => {
      try {
        setLoading(true); // Start loading
        const docRef = doc(firestore, "Other", "DoctorProfile");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          var Data = [];
          Object.entries(docSnap.data()).forEach(([key, value]) => {
            Data.push(Object.assign({ Id: key }, value));
          });
          const sortedData = Data.sort((a, b) => a.Priority - b.Priority);
          setDoctors(sortedData);

          if (sortedData.length === 0) {
            setNoData(true); // No data available
          }
        } else {
          setNoData(true); // No data found in Firestore
          setDoctors([]);
        }
      } catch (error) {
        console.error("Error fetching doctors: ", error);
        setNoData(true); // Error case, no data available
      } finally {
        setLoading(false); // End loading
      }
    };

    fetch_1();
  }, []);

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title text-white title-dark">
                  Doctors team
                </h3>
                <p className="para-desc mx-auto text-white-50">
                  Great doctor if you need your family member to get effective
                  immediate assistance, emergency treatment or a simple
                  consultation.
                </p>

                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      hospital ~ Doctors team
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section" style={{ marginBottom: "2rem" }}>
        <div className="container">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4>Doctors Team</h4>
          </div>
          {loading ? (
            <div className="text-center">
              <h5>Loading...</h5>
            </div>
          ) : noData ? (
            <div className="text-center">
              <h5>No data available</h5>
            </div>
          ) : (
            <div className="row align-items-center">
              {Doctors?.map((item, index) => (
                <div className="col-lg-6 col-md-12 mt-4 pt-2" key={index}>
                  <div className="card team border-0 rounded shadow overflow-hidden">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="team-person position-relative overflow-hidden">
                          <img src={item.Image} className="img-fluid" alt="" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card-body">
                          <Link
                            to="#"
                            className="title text-dark h5 d-block mb-0"
                          >
                            {item.Name}
                          </Link>
                          <small className="text-muted speciality">
                            {item.Qualification}
                          </small>
                          <hr />
                          <ul className="list-unstyled mt-2 mb-0">
                            <li className="d-flex ms-0 mt-2">
                              <small className="text-muted four-line-truncate justify">
                                {item.Description}
                              </small>
                            </li>
                          </ul>
                          <div className="post-meta d-flex justify-content-between mt-3">
                            <p
                              onClick={() => handleViewDetails(item)}
                              className="link"
                              style={{ cursor: "pointer" }}
                            >
                              View Details
                              <i className="mdi mdi-chevron-right align-middle"></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
