import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import bg1 from "../../assets/images/bg/14.jpg";
import ScrollTop from "../../components/scrollTop";
import Home4 from "../../assets/images/dms/swamiji.png";

const SwamijiDetails = () => {
  const renderSectionTitle = () => (
    <div className="section-title text-center">
      <h3 className="sub-title mb-4 text-white title-dark">Blogs & News</h3>
      <p className="para-desc mx-auto text-white-50">
        Insights and practical tips empowering one to achieve mental, physical,
        and spiritual well-being.
      </p>
      <nav aria-label="breadcrumb" className="d-inline-block mt-3">
        <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
          <li className="breadcrumb-item">
            <Link to="/">Doctris</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Blogs
          </li>
        </ul>
      </nav>
    </div>
  );

  const renderParagraphs = () => (
    <>
      <p className="text-muted mt-4 justify">
        His Holiness Sri Sri Maridevaru Shivayogi Mahaswamiji is truly an
        incarnation, sacred, ideal, eternal human being who dedicated his life
        to educate men. His unmatched energy, devotion has illuminated paths of
        many. Even now, after his demise, many of his devotees have felt his
        presence or power through prayers which are answered. God is
        transcendent, yes, but at the same time, he is said to be a form that
        represents humans. He is supreme spirit, but patterned as men. Thus our
        Seer Sri Sri Maridevaru Shivayogi Mahaswamiji was one of the
        incarnations of God who lived among men.
      </p>
      <p className="text-muted mt-4 justify">
        His birth and life history are interesting, surprising and inspiring.
        Sharana couple Balaji and Jayamma of Sangli, Maharashtra had no children
        for many years of their marriage. They worshipped ‘Sri Durdandeshwara
        Mutt’ everyday with devotion one night the couple dream of
        ‘Durdandeshwara’ blessing them in their dream. The very next day when
        they visit the ‘Gaddige’ as usual, the Seer there blesses them and says,
        their wishes would be fulfilled shortly. Thus Sri Maridevaru Shivayogi
        Mahaswamiji was born with blessings.
      </p>
      <p className="text-muted mt-4 justify">
        Childhood of Sri Maridevaru Shivayogi Mahswamiji was filled with
        divinity, spirituality and meditation. Even as a playful child, he
        always involved in progress, performed puja and other rituals. For him
        small idols of different Gods were itself toys. He was very much
        interested in spiritual aspects since his very young age. Once when his
        family visited Seer of Durdandeshwara Mutt, the elderly Seer very much
        impressed with the child and asked his parents to leave the boy with
        him. Maridevaru Sri though from a wealthy family, never was interested
        in any materialistic life. He had started to satisfy his thirst for
        spirituality at very young age. He followed routines of his Guru at the
        Mutt, served there while he got his spiritual education. Later he
        travels to Durdandeshwara Mutt at Muragoda, Belgaum and after that to
        Belavadi. He stays there for some time and again travels to
        Durdandeshwara Mutt at Bailahongala, Belgaum, where he is anointed as
        the Seer. There he gains respect of devotees and is called ‘Mangalanatha
        Shivayogi,’ and ‘Mangalanatha Ajja.’
      </p>
      <p className="text-muted mt-4 justify">
        Stagnant water is always filled with muck, while flowing water becomes
        river and thus useful. It creates lives, builds civilisations, and makes
        the lands green and so on. A Jangama (Shaiva order of wandering monk)
        never stays at a single place, like flowing water. He moves around to
        spread knowledge. Thus, Seer Sri Maridevaru Shivayogi Mahaswamiji
        travelled to many places propagating the word of God. He stayed at
        Dharawad for some time, where he meditated for few years. His holy
        powers attained through such asceticism helped in empathising with the
        sick and grieved. His words were a wonder to devotees and many
        experienced the healing powers in him. Nonetheless, the radiance emitted
        through his intellectuality were a guiding light for people around him
        to live in harmony, develop their personality which did ensemble their
        devotion towards Lord Shiva while establishing the values required to
        live in a society.
      </p>
      <p className="text-muted mt-4 justify">
        Restlessly travelling from North to South and East to West, alongside
        conducting pilgrimages, Seer Sri Maridevaru Shivayogi Mahaswamiji, stays
        for while at a cave on Shambulingeshwara Hills at Chilakavaadi of
        Kollegal, Chamarajanagar District in Karnataka. He then moves to Beby
        village in Pandavapura Taluk of Mandya District, where he is welcomed by
        his devotee Shivappa and others. He stays there to impart his wisdom and
        knowledge. People in that sphere see their religious leader in him thus
        he gains their love and immense respect.
      </p>
      <p className="text-muted mt-4 justify">
        Beby Betta or Beby Hills in the village was already a holy place for the
        residents there. The hills where Mahadeshwara and Siddeshwara Temples
        were situated hosted annual car festival with great fervour. Like most
        of the religious reformers facing hurdles in their path, he had a tough
        time while he initiated to progressive works. He restored a ruined
        structure (Mantap) with the little offerings he got from his devotees
        and established ‘Ramayogeeshwara Mutt’ in 1966. Initial days were so
        difficult, that he used to eat just puffed rice (mandakki) as his food.
        The place hosted many sages and religious leaders. In later days, he
        started Goshale (cow shed) to protect stray cows and established
        schools, hostels for education of poor children in the surrounding area.
        His pro social works earned him great respect. He left the place
        disheartened for various reasons after appointing a successor to the
        Mutt. Journeying for few years, later when he arrived at the same
        village, residents forced him to stay back and even offered him some
        piece of land. He established Sri Durdandeshwara Mahanta Shivayogi Mutt.
      </p>
      <p className="text-muted mt-4 justify">
        Since his association with the villagers was on good terms, he was able
        to re- establish himself in the village. With seamless effort and help
        by the villagers, Sri Maridevaru Shivayogi Mahaswamiji continued the
        tradition of Dasoha (sharing or giving something in charity), be it,
        food or education. Through his meditative powers and healing powers, he
        became the favourite of the people. They had a special place for him in
        their hearts. Devotees increased in number day by day.
      </p>
      <p className="text-muted mt-4 justify">
        Pilgrim towns or shrines will have their own geographical and
        mythological importance. In some instances, the importance is gained by
        holy men visiting those places or staying there. Beby Hills is one among
        them. It is abode of Lord Mahadeshwara and is surrounded by green forest
        everywhere. The cave in the hills is said to have hosted many sages,
        hermits who spent time meditating in order to attain austerity and
        peace. Lord Siddeshwara Temple which is on the same hills elevates the
        holiness of the place. Sri Maridevaru Shivayogi Mahaswamiji, as
        mentioned before also meditated here, through which he is said to have
        gained more powers to heal people of their illness or lessen their
        grievances. His strong devotion in God, worshiping him endlessly, while
        he guided people out of the darkness to knowledge, made him popular
        among the villagers. Devotees were overwhelmed by selfless service to
        those who sought help. He earned immense respect and his divine powers
        were a blessings to his devotees. No one, who sought help would return
        empty handed from the Mutt.
      </p>
      <p className="text-muted mt-4 justify">
        People believed that he possessed healing powers. Illness of many, which
        were incurable by medicines, were cured with his spiritual powers and
        the herbal medicines he gave. He believed that every individual in the
        society should receive word of God and his blessings. His dream of
        harmonious society and his efforts towards the same, must be
        appreciated. He never discriminated people based on their caste,
        economic conditions and races. He always made sure the last person in
        the society received blessings from God. Probably, because of all the
        special qualities he possessed.
      </p>
      <p className="text-muted mt-4 justify">
        Swamiji lived for 113 years and spent most of his life in empowering the
        needy. The Seer who spoke Marathi and Kannada won the hearts of people.
        The present Sri Durdandeshwara Mutt at Beby village was inaugurated by
        Dr Sri Sri Sri Shivakumara Mahaswamiji of Sri Siddaganga Mutt, Tumkur in
        1994. He worked for the welfare of peopleand performed pujas on
        auspicious occasions as well as full moon days (Purnima).
      </p>
      <p className="text-muted mt-4 justify">
        Seer Sri Sri Maridevaru Shivayogi Mahaswamiji lived a life of a saint
        and an intellectual being. His values and ideals must be followed by
        every individual in the society, which are helpful for both personal and
        social life.
      </p>
    </>
  );

  return (
    <div>
      <Navbar
        manuClass="navigation-menu nav-light nav-left"
        containerClass="container"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: "center" }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">{renderSectionTitle()}</div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container">
        <div
          style={{
            marginTop: "3rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={Home4} className="img-fluid rounded shadow" alt="Swamiji" />
        </div>
        {renderParagraphs()}
      </div>
      <Footer />
      <ScrollTop />
    </div>
  );
};

export default SwamijiDetails;
